import { SET_LANGUAGE, GET_LANGUAGE } from "./types";
import _ from "lodash";
import axios from "axios";
import { api } from "../../services";

import english from "../../Languages/English.json";
import dutch from "../../Languages/Dutch.json";

//ref to the debounce function which is called when setting the new language
const debounceUpdateLanguageDatabase = _.debounce(function(token, currentLanguage) {
    updateLanguageDatabase(token, currentLanguage);
}, 200);

/**
 * Pushes an update the the database with the new language key
 * @param token the token object
 * @param language the new language key as string
 */
const updateLanguageDatabase = (token, language) => {
    axios({
        method: "post",
        url: `${api.url}/resources`,
        data: { query: `mutation{update_user(data:{language:"${language}"}){language}}` },
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` }
    })
};

/**
 * Changes the language of the website
 * @param {string} token
 */
export const setLanguage = token => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            const lang = localStorage.getItem("lang");

            const newLang = lang === "Dutch" ? english : dutch;

            localStorage.setItem("lang", newLang.dashboard.currentLanguage);

            dispatch({
                type: SET_LANGUAGE,
                payload: newLang
            });

            debounceUpdateLanguageDatabase(token, newLang.dashboard.currentLanguage);

            resolve();
        } catch (error) {
            reject(error);
            console.error("SET_LANGUAGE::FAIL", error);
        }
    });
};

/**
 * Gets the language from the db and sets it to the state
 * @param {string} token
 */
export const getLanguage = token => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            const lang = localStorage.getItem("lang");

            if (lang === null) {
                
                axios({
                    method: "post",
                    url: `${api.url}/resources`,
                    data: { query: "query{user{language}}" },
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${token}` }
                }).then(res => {
                    if(res.data.data.user.language !== null)
                    {
                        const currentLang = res.data.data.user.language === "Dutch" ? dutch : english;
                        
                        dispatch({
                            type: GET_LANGUAGE,
                            payload: currentLang
                        });

                        //because the setItem is synchronous push the update last
                        localStorage.setItem("lang", currentLang.dashboard.currentLanguage);

                        resolve();
                    }

                }).catch(error => {
                    reject(error);
                    console.error("GET_LANGUAGE::FAIL", error);
                });
            }
        } catch (error) {
            reject(error);
            console.error("GET_LANGUAGE::FAIL", error);
        }
    });
};
