import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    Checkbox,
    ListItemText,
    ListItemSecondaryAction,
    CircularProgress,
    Tooltip,
    IconButton
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { Help } from "@material-ui/icons";
import { connect } from "react-redux";

import { mapStateToProps } from "../../../redux";
import { createLog } from "../../../Actions/logActions/actions";

import { CalculatorContext } from "../../../Contexts/CalculatorContext";

import Input from "../../Inputs/InputField/InputField";
import { format } from "../../../helperFunctions";
import CustomSelect from "../../Inputs/Select/Select";

import "./ProductDialogStyles.css";

function ProductDialog(props) {
    const context = useContext(CalculatorContext);

    const [products, setProducts] = useState([]);
    const [areProductsLoading, setAreProductsLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({ code: null });

    const [lastProductRequestProps, setLastProductRequestProps] = useState("");

    useEffect(() => {
        const { product } = context;

        if (props.open) {
            const newProductRequestProps = generateProductHash(context);

            if (newProductRequestProps !== lastProductRequestProps) {
                searchProducts().then(() => {
                    setSelectedProduct({ code: null });
                    setLastProductRequestProps(newProductRequestProps);
                });
            } else {
                setSelectedProduct({ code: product.code });
            }
        }
    }, [props.open]);

    const searchProducts = () => {
        return new Promise((resolve, reject) => {
            try {
                const { functions } = context;

                setAreProductsLoading(true);

                functions.requestProducts().then(products => {
                    setProducts(products);
                    setAreProductsLoading(false);
                    resolve();
                });
            } catch (error) {
                reject(error);
            }
        });
    };

    const generateProductHash = context => {
        return `${context.mortgageAmount.value}-${context.valueHouse.value}-${JSON.stringify(
            context.parts.parts.map(part => {
                return JSON.stringify({ type: part.mortgageType.requestValue, period: part.interestFixedPeriod.requestValue });
            })
        )}`;
    };

    const handleNewProductSelection = () => {
        const { auth, createLog, onClose } = props;
        const { functions } = context;

        functions.requestProduct(selectedProduct.code).then(product => {
            functions.requestCalculation().then(() => {
                functions.requestMaxMortgageFromIncomeCalculation().then(newMaxMortgageIncome => {
                    createLog(auth, { ...context, product: product, maxMortgageAmountIncome: newMaxMortgageIncome });
                });

                onClose();
            });
        });
    };

    //no need for defining available options as if the product has fixed type as an option
    //when renderitng and callcing this function for "fixed" it will return null and
    //the option will be filtered out(not rendered)
    const translateType = type => {
        switch (type) {
            case "annuity":
                return lang.dashboard.input_mortgage_type_part_value_annuity;
            case "linear":
                return lang.dashboard.input_mortgage_type_part_value_linear;
            default:
                return null;
        }
    };

    const { theme, open, lang, css, onClose } = props;

    const numberOfParts = context.parts.parts.length;

    return (
        <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
            <DialogTitle>{lang.product.modal_title}</DialogTitle>
            <DialogContent className={"dialog-content"}>
                <div className={"content-wrapper"}>
                    <div className={"content-side product-selection-input-box"}>
                        <Input
                            id={"mortgage-amount"}
                            label={lang.product.mortgage_amount_label}
                            tooltipText={lang.product.mortgage_amount_help}
                            value={format(context.mortgageAmount.value, 0, 3, ".", ",")}
                            readOnly={true}
                        />
                        <Input
                            id={"value-house"}
                            label={lang.product.value_house_label}
                            tooltipText={lang.product.value_house_help}
                            value={format(context.valueHouse.value, 0, 3, ".", ",")}
                            readOnly={true}
                        />
                        <Input
                            id={"nhg"}
                            label={lang.product.nhg_label}
                            tooltipText={lang.product.nhg_help}
                            type="text"
                            value={
                                context.isNhgPossible.value
                                    ? !context.forceNoNhg.value
                                        ? lang.product.nhg_yes
                                        : lang.product.nhg_no
                                    : lang.product.nhg_no
                            }
                            readOnly={true}
                        />
                        <Input
                            id={"ltv"}
                            label={lang.product.ltv_label}
                            type="text"
                            tooltipText={lang.product.ltv_help}
                            value={`${context.ltv.value}%`}
                            readOnly={true}
                        />
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {context.parts.parts.map((part, key) => {
                                return (
                                    <div
                                        key={key}
                                        style={{
                                            width: `${100 / numberOfParts}%`,
                                            marginRight: numberOfParts > 1 && key === 0 ? "20px" : 0
                                        }}
                                    >
                                        <CustomSelect
                                            id={`mortgage-type-${key}`}
                                            name={`mortgage-type-${key}`}
                                            label={`${lang.product.input_mortgage_type_label} ${key + 1}`}
                                            value={part.mortgageType.requestValue}
                                            onChange={event => {
                                                context.parts.setPartMortgageTypeRequest(event.target.value, key, () => {
                                                    searchProducts();
                                                });
                                            }}
                                            options={context.product.available_types
                                                .map(option => {
                                                    return { name: translateType(option), value: option };
                                                })
                                                .filter(value => {
                                                    return value.name !== null;
                                                })}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {context.parts.parts.map((part, key) => {
                                //the checking is done because when the user switches from 1 part to 2 there are no available_products props in
                                //the porduct(as it is a product with 1 part) so tot takle the problem if the periods are not found fallback
                                //to the first items available products(which will always be valid as the new product is a copy of the
                                //new, just with a different mortgage amount)
                                const available_periods =
                                    context.product.available_periods[key] !== undefined
                                        ? context.product.available_periods[key].available_periods
                                        : context.product.available_periods[0].available_periods;
                                return (
                                    <div
                                        key={key}
                                        style={{
                                            width: `${100 / numberOfParts}%`,
                                            marginRight: numberOfParts > 1 && key === 0 ? "20px" : 0
                                        }}
                                    >
                                        <CustomSelect
                                            id={`interest-fixed-period-${key}`}
                                            name={`interest-fixed-period-${key}`}
                                            label={`${lang.product.input_interest_fixed_period_label} ${key + 1}`}
                                            value={part.interestFixedPeriod.requestValue}
                                            onChange={event => {
                                                context.parts.setPartInterestFixedPeriodRequest(event.target.value, key, () => {
                                                    searchProducts();
                                                });
                                            }}
                                            options={available_periods.map(option => {
                                                return { name: option.toString(), value: option };
                                            })}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={"content-side"} style={{ overflowY: "auto" }}>
                        {areProductsLoading ? (
                            <div
                                style={{
                                    display: "flex",
                                    height: "100%",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <CircularProgress />
                            </div>
                        ) : (
                            <List component="div">
                                {products.map((product, index) => {
                                    return (
                                        <ListItem
                                            button
                                            key={index}
                                            onClick={() => {
                                                setSelectedProduct({ code: product.code });
                                            }}
                                            style={css.isMobile ? { padding: "10px 0" } : null}
                                        >
                                            <Checkbox
                                                style={css.isMobile ? { padding: "0" } : null}
                                                checked={product.code === context.product.code || selectedProduct.code === product.code}
                                                tabIndex={index}
                                                disableRipple
                                            />
                                            <ListItemText
                                                className={"list-item-text"}
                                                primary={product.provider.name}
                                                secondary={product.description}
                                            />
                                            <ListItemSecondaryAction
                                                style={!css.isMobile ? { display: "flex", flexDirection: "row" } : null}
                                            >
                                                {product.rates.map((part, key) => {
                                                    const rate = format(part[context.interestRateKey] * 100, 2, 3, ".", ",");

                                                    return (
                                                        <div
                                                            style={
                                                                css.isMobile
                                                                    ? {
                                                                          display: "flex",
                                                                          alignItems: "center",
                                                                          justifyContent: "flex-end"
                                                                      }
                                                                    : {
                                                                          display: "flex",
                                                                          width: "50px",
                                                                          alignItems: "center",
                                                                          justifyContent: "center"
                                                                      }
                                                            }
                                                            key={key}
                                                        >
                                                            {rate === "NaN" ? "-" : `${rate}%`}
                                                        </div>
                                                    );
                                                })}
                                                <Tooltip
                                                    title={product.explanation}
                                                    aria-label={product.explanation}
                                                    disableFocusListener
                                                    disableTouchListener
                                                >
                                                    <IconButton aria-label="info" style={{ marginLeft: 10 }}>
                                                        <Help style={{ color: theme.palette.secondary.main }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color={"secondary"}>
                    {lang.product.button_cancel}
                </Button>
                <Button onClick={handleNewProductSelection} variant={"contained"} color={"secondary"}>
                    {lang.product.button_apply}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ProductDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default connect(
    mapStateToProps,
    { createLog }
)(withTheme()(ProductDialog));
