export const getMortgageProductQuery = `
query getMortgageProduct($code: String, $parts: [MortgagePartsInput!]!){
    mortgage_product(code: $code, parts: $parts){
        code
        explanation
        description
        interest_adjustment_ltv_house_during_ifp
        interest_adjustment_ltv_mortgage_during_ifp
        interest_adjustment_ltv_mortgage_during_ifp_provider
        provider {
            name
            logo
        }
        available_types
        available_periods{
            type
            available_periods
        }
        rates {
            type
            period
            nhg90
            nhg
            ltv_0_40
            ltv_0_45
            ltv_0_50
            ltv_0_54
            ltv_0_55
            ltv_0_60
            ltv_0_61
            ltv_0_65
            ltv_0_66
            ltv_0_675
            ltv_0_70
            ltv_0_71
            ltv_0_75
            ltv_0_80
            ltv_0_81
            ltv_0_85
            ltv_0_88
            ltv_0_90
            ltv_0_91
            ltv_0_95
            ltv_1_00
            ltv_1_01
            ltv_1_02
            ltv_1_03
            ltv_1_04
            ltv_1_05
            ltv_1_06
            ltv_1_08
            ltv_1_10
            ltv_1_125
            ltv_1_15
            ltv_1_20
        }
    }
}
`;

export const getMortgageProductsQuery = (key) => {
    return `
    query getMortgageProducts($parts: [MortgagePartsInput!]!){
        mortgage_products(parts: $parts){
            code
            description
            explanation
            provider{
                name
                logo
            }
            rates{
                type
                ${key}
            }
        }
    }
    `;
}