import { MENU_CLOSE, MENU_OPEN, MENU_TOGGLE } from "../Actions/menuActions/types";
import { log } from "../redux";

const initialState = {
    isOpen: false,
    menuOpenWidth: 200,
    menuCloseWidth: 57
};

export default function(state = initialState, action) {
    switch (action.type) {
        case MENU_CLOSE:
            log(MENU_CLOSE, action);
            return {
                ...state,
                isOpen: action.payload
            };
        case MENU_OPEN:
            log(MENU_OPEN, action);
            return {
                ...state,
                isOpen: action.payload
            };
        case MENU_TOGGLE:
            log(MENU_TOGGLE, action);
            return {
                ...state,
                isOpen: !state.isOpen
            };
        default:
            return state;
    }
}
