import _ from "lodash";
import moment from "moment";

import {
    LOG_CREATE,
    LOG_GET,
    LOG_GET_LAST_LOG,
    LOG_DELETE,
    LOG_DELETE_LOCAL,
    LOG_UNLOAD,
    LOG_LOAD_LOGS
} from "../Actions/logActions/types";

import { log } from "../redux";

const initialState = {
    //logs array
    logs: [],

    //offset for the request to the logs
    offset: 0,

    //identifier to show if the logs were loaded from the backend
    areLogsLoaded: false,

    //stores the current loaded log
    currentLog: null,

    //stores the log from the last time the user used the application
    lastLog: null,

    hasLoadedAllLogs: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case LOG_CREATE:
            log(LOG_CREATE, action);

            const newLogs = _.cloneDeep(state.logs);

            const newLog = action.payload;

            if (state.areLogsLoaded) {
                const date = moment(newLog.created_at, "x").toDate();

                let formatedDateFromLog = moment(newLog.created_at, "x").format("dddd DD MMM YYYY");

                if (newLogs.length > 0 && formatedDateFromLog === newLogs[newLogs.length - 1].formatedDate) {
                    newLogs[newLogs.length - 1]["logs"].unshift(newLog);
                } else {
                    newLogs.push({ formatedDate: formatedDateFromLog, date: date, logs: [newLog] });
                }
            }
            
            return {
                ...state,
                logs: newLogs
            };
        case LOG_DELETE:
            log(LOG_DELETE, action);
            return state;
        case LOG_DELETE_LOCAL:
            log(LOG_DELETE_LOCAL, action);
            return {
                ...state,
                logs: action.payload
            };
        case LOG_UNLOAD:
            log(LOG_UNLOAD, action);
            return {
                ...state,
                currentLog: action.payload
            };
        case LOG_GET:
            log(LOG_GET, action);
            return {
                ...state,
                currentLog: action.payload
            };
        case LOG_GET_LAST_LOG:
            log(LOG_GET_LAST_LOG, action);
            return {
                ...state,
                lastLog: action.payload
            };
        case LOG_LOAD_LOGS:
            log(LOG_LOAD_LOGS, action);
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
