import React from "react";
import { Typography, Paper, IconButton, Collapse } from "@material-ui/core";
import { Warning, CheckCircle } from "@material-ui/icons";
import { format } from "./../../../helperFunctions";
import { mapStateToProps } from "../../../redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";

function CheckBlock(props) {
    const { calculatorContext, lang, classes, css } = props;
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div className={"check-block-container"}>
            <Paper className={"check-block-paper"}>
                <div className={"check-block-header"}>
                    <Typography variant={"h1"}>{lang.dashboard.maximum_mortgage_title}</Typography>
                    <div className={"check-block-icon-button"}>
                        <IconButton
                            className={classNames(classes.expand, {
                                [classes.expandOpen]: expanded
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </div>
                </div>
                <Collapse in={css.isMobile ? expanded : true} timeout="auto" unmountOnExit>
                    <div className={"check-block-item"}>
                        <Typography variant={"h2"}>{lang.dashboard.maximum_mortgage_house}</Typography>
                        <div className={"check-block-item-wrapper"}>
                            <Typography>{`€ ${format(calculatorContext.maxMortgageHouse.value, 0, 3, ".", ",")}`}</Typography>
                            {calculatorContext.mortgageAmount.value > calculatorContext.maxMortgageHouse.value ? (
                                <Warning style={{ color: "#F44336" }} />
                            ) : (
                                <CheckCircle style={{ color: "#66BB6A" }} />
                            )}
                        </div>
                    </div>
                    <div className={"check-block-item"}>
                        <Typography variant={"h2"}>{lang.dashboard.maximum_mortgage_income}</Typography>
                        <div className={"check-block-item-wrapper"}>
                            <Typography>{`€ ${format(calculatorContext.maxMortgageAmountIncome, 0, 3, ".", ",")}`}</Typography>
                            {calculatorContext.mortgageAmount.value > calculatorContext.maxMortgageAmountIncome ? (
                                <Warning style={{ color: "#F44336" }} />
                            ) : (
                                <CheckCircle style={{ color: "#66BB6A" }} />
                            )}
                        </div>
                    </div>
                </Collapse>
            </Paper>
        </div>
    );
}

const styles = theme => ({
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        }),
        padding: "0"
    },
    expandOpen: {
        transform: "rotate(180deg)"
    }
});

export default connect(mapStateToProps)(withStyles(styles)(CheckBlock));
