/**
 * root Reducer
 */
import { combineReducers } from "redux";
import authReducer from "./authReducer";
import menuReducer from "./menuReducer";
import logReducer from "./logReducer";
import langReducer from "./languageReducer";
import cssReducer from "./cssReducer";

// import calculationReducer from "./calculationReducer";
// import inputReducer from "./inputReducer";

export default combineReducers({
    auth: authReducer,
    menu: menuReducer,
    logs: logReducer,
    lang: langReducer,
    css: cssReducer
});
