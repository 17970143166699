import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    Typography,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper
} from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { mapStateToProps } from "../../../redux";

import "./InformationDialogStyles.css";

const InformationDialog = React.memo(
    function InformationDialog(props) {
        const { mainAction, isOpen, payload, lang } = props;

        if (payload.activePayload.length > 0) {
            const info = payload.activePayload[0].payload;

            return (
                <Dialog open={isOpen} fullWidth={true} maxWidth={"sm"}>
                    <DialogTitle>
                        {lang.graphInterestDetail.modal_title} {info.yearlyValues.label}
                    </DialogTitle>
                    <DialogContent>
                        <Typography
                            style={{ marginBottom: 10 }}
                        >{`${lang.graphInterestDetail.interest_rate_current_year_label} ${info.yearlyValues.value}`}</Typography>
                        <Paper>
                            <Table className={"monthly-interest-rate-table"}>
                                <TableHead>
                                    <TableRow className={"table-row"}>
                                        <TableCell>{lang.graphInterestDetail.interest_rate_table_month_header}</TableCell>
                                        <TableCell>{lang.graphInterestDetail.interest_rate_table_part_1_header}</TableCell>
                                        <TableCell>{lang.graphInterestDetail.interest_rate_table_part_2_header}</TableCell>
                                        <TableCell>{lang.graphInterestDetail.interest_rate_table_average_header}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {info.monthlyValues.map((monthlyValue, index) => {
                                        return (
                                            <TableRow key={index} className={"table-row"}>
                                                <TableCell>{monthlyValue.label}</TableCell>
                                                <TableCell>{monthlyValue.values[1] === null ? "-" : monthlyValue.values[1]}</TableCell>
                                                <TableCell>{monthlyValue.values[2] === null ? "-" : monthlyValue.values[2]}</TableCell>
                                                <TableCell>{monthlyValue.values[0] === null ? "-" : monthlyValue.values[0]}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={mainAction} color={"secondary"} variant={"contained"}>
                            {lang.graphInterestDetail.mortgage_amount_modal_button_apply}
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return "";
    },
    (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen
);

InformationDialog.propTypes = {
    payload: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    mainAction: PropTypes.func.isRequired
};

InformationDialog.defaultProps = {
    payload: {},
    isOpen: false
};

export default connect(mapStateToProps)(InformationDialog);
