import React from "react";
import {
    Typography,
    TextField,
    Button,
    FormControl,
    FormHelperText,
    CircularProgress,
    Card,
    CardContent
    // Tabs,
    // Tab
} from "@material-ui/core";
import "./SignUpStyles.css";
import { connect } from "react-redux";

import { mapStateToProps } from "../../redux";
import { signUpDefault } from "../../Actions/authActions/actions";

import logo from "../../Images/logo_hyponav_beta.svg";

class SignUp extends React.Component {
    state = {
        email: "",
        password: "",
        firstName: "",
        confirmPassword: "",
        // companyName: "",
        errors: {
            email: false,
            password: false,
            firstName: false,
            confirmPassword: false,
            companyName: false
        },
        emailErrorMessage: "",
        isLoading: false,
        role: "basic"
    };

    onInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    signUp = () => {
        const { email, password, confirmPassword, firstName, errors, role } = this.state;

        this.setState({ isLoading: true });

        if (firstName.length === 0) {
            this.setState({ errors: { ...errors, firstName: true }, isLoading: false });
            return;
        } else {
            this.setState({ errors: { ...errors, firstName: false } });
        }

        // if (role !== "basic" && companyName.length === 0) {
        //     this.setState({ errors: { ...errors, companyName: true }, isLoading: false });
        //     return;
        // } else {
        //     this.setState({ errors: { ...errors, companyName: false } });
        // }

        //checking if the 2 passwords match
        if (confirmPassword !== password) {
            this.setState({ errors: { ...errors, confirmPassword: true }, isLoading: false });
            return;
        } else {
            this.setState({ errors: { ...errors, confirmPassword: false } });
        }

        this.props
            .signUpDefault({
                firstName: firstName,
                email: email,
                password: password,
                userName: firstName,
                confirmPassword: confirmPassword,
                role: role
                // companyName: companyName
            })
            .then(response => {
                if (response.code === "email/sent") {
                    this.props.history.push("/send");
                }
            })
            .catch(error => {
                this.setState({
                    errors: {
                        ...errors,
                        email: error.code.includes("email") || error.code === "email/taken" ? true : false,
                        password: error.code.includes("password") ? true : false
                    },
                    isLoading: false,
                    emailErrorMessage:
                        error.code === "email/taken"
                            ? "The email address is already in use by another account."
                            : "The email address is badly formatted."
                });
            });
    };

    redirectToLogin = () => {
        this.props.history.push("/login");
    };

    // handleTabChange = (event, role) => {
    //     this.setState({ role: role });
    // };

    render() {
        const { errors, isLoading, emailErrorMessage } = this.state;

        return (
            <div className={"dom"}>
                <img src={logo} alt="Logo" style={{ width: "300px", margin: "40px 0" }} />
                <Card className={"card"}>
                    <CardContent className={"card-content"}>
                        {/* <Tabs value={role} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                            <Tab label="Client" value="basic" className={"tabs"} />
                            <Tab label="Advisor" value="advisor" className={"tabs"} />
                            <Tab label="Manager" value="manager" className={"tabs"} />
                        </Tabs> */}
                        <Typography variant={"h2"} style={{ margin: "20px 0" }}>
                            Register
                        </Typography>
                        <FormControl error={errors.firstName} className={"sign-form-input"}>
                            <TextField
                                error={errors.firstName}
                                label="First name"
                                variant={"outlined"}
                                className={"sign-input"}
                                type="text"
                                name="firstName"
                                onChange={this.onInputChange}
                                margin="normal"
                            />
                            {errors.email ? (
                                <FormHelperText className={"error-message"}>The field must not be empty.</FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>
                        <FormControl error={errors.email} className={"sign-form-input"}>
                            <TextField
                                error={errors.email}
                                label="Email"
                                variant={"outlined"}
                                className={"sign-input"}
                                type="email"
                                name="email"
                                onChange={this.onInputChange}
                                autoComplete="email"
                                margin="normal"
                            />
                            {errors.email ? <FormHelperText className={"error-message"}>{emailErrorMessage}</FormHelperText> : undefined}
                        </FormControl>

                        <FormControl error={errors.password} className={"sign-form-input"}>
                            <TextField
                                error={errors.password}
                                label="Password"
                                className={"sign-input"}
                                variant={"outlined"}
                                type="password"
                                name="password"
                                onChange={this.onInputChange}
                                autoComplete="current-password"
                                margin="normal"
                            />
                            {errors.password ? (
                                <FormHelperText className={"error-message"}>
                                    The password must contain only letters and numbers and must be 6 characters long or more.
                                </FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>

                        <FormControl error={errors.confirmPassword} className={"sign-form-input"}>
                            <TextField
                                error={errors.confirmPassword}
                                label="Confirm Password"
                                className={"sign-input"}
                                variant={"outlined"}
                                type="password"
                                name="confirmPassword"
                                onChange={this.onInputChange}
                                autoComplete="current-password"
                                margin="normal"
                            />
                            {errors.confirmPassword ? (
                                <FormHelperText className={"error-message"}>The passwords do not match.</FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>
                        {/* {role === "advisor" || role === "manager" ? (
                            <FormControl error={errors.companyName} className={"sign-form-input"}>
                                <TextField
                                    error={errors.companyName}
                                    label="Company name"
                                    variant={"outlined"}
                                    className={"sign-input"}
                                    type="text"
                                    name="companyName"
                                    onChange={this.onInputChange}
                                    margin="normal"
                                />
                                {errors.email ? (
                                    <FormHelperText className={"error-message"}>The field must not be empty.</FormHelperText>
                                ) : (
                                    undefined
                                )}
                            </FormControl>
                        ) : null} */}
                        <Button size="large" color="secondary" variant="contained" className={"sign-up-button"} onClick={this.signUp}>
                            {isLoading ? <CircularProgress className={"loader"} /> : "Sign up"}
                        </Button>

                        <div className={"sign-help"}>
                            <Button variant="text" color="primary" onClick={this.redirectToLogin}>
                                Already have an account?
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    { signUpDefault }
)(SignUp);
