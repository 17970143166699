import moment from "moment";
import axios from "axios";
import _ from "lodash";

import { LOG_CREATE, LOG_GET, LOG_GET_LAST_LOG, LOG_DELETE, LOG_DELETE_LOCAL, LOG_UNLOAD, LOG_LOAD_LOGS } from "./types";

import { api } from "../../services";

import { lastMortgageLogQuery, getMortgageLogQuery, getLogsQuery } from "../../Graphql/queries/logQueries";
import { createMortgageLogMutation, softDeleteLogMutation } from "../../Graphql/mutations/logMutations";

/**
 * Creates a log
 * * The function returns a Promise
 * * * then: returns createdLogId<Integer>
 * * * catch: return error info
 * @param {Object} auth object from the store
 * @param {Object} calculatorContext
 */
export const createLog = (auth, calculatorContext) => dispatch => {
    return new Promise((resolve, reject) => {
        const variables = {
            data: {
                mortgage_amount: calculatorContext.mortgageAmount.value,
                value_house: calculatorContext.valueHouse.value,
                purchase_price_house: calculatorContext.purchasePriceHouse.value,
                parts: calculatorContext.parts.parts.map(part => {
                    return {
                        mortgage_amount: part.mortgageAmount.value,
                        mortgage_type: part.mortgageType.value,
                        duration: part.duration.value,
                        interest_fixed_period: part.interestFixedPeriod.value.toString(),
                        interest_rate: part.interestRate.value
                    };
                }),
                incomes: calculatorContext.applicants.incomes.map(income => {
                    return income.value;
                }),
                start_date: calculatorContext.startDate.value,
                force_no_nhg: calculatorContext.forceNoNhg.value,
                transfer_tax: calculatorContext.transferTax.value,
                notary_deed_of_transfer: calculatorContext.notaryDeedOfTransfer.value,
                bank_guarantee: calculatorContext.bankGuarantee.value,
                architectural_inspection: calculatorContext.architecturalInspection.value,
                notary_mortgage: calculatorContext.notaryMortgage.value,
                evaluation: calculatorContext.valuation.value,
                mortgage_advice: calculatorContext.mortgageAdvice.value,
                mortgage_application: calculatorContext.mortgageApplication.value,
                interest_rate_key: calculatorContext.interestRateKey,
                ltv: calculatorContext.ltv.value
            },
            product: calculatorContext.product,
            calculations: {
                max_mortgage_from_income: calculatorContext.maxMortgageAmountIncome,
                max_mortgage_from_house: calculatorContext.maxMortgageHouse.value,
                is_nhg_possible: calculatorContext.isNhgPossible.value,
                required_mortgage_amount: calculatorContext.requiredMortgageAmount.value,
                required_savings: calculatorContext.requiredSavings.value,
                nhg_costs: calculatorContext.nhgCosts.value
            }
        };

        axios({
            method: "post",
            url: `${api.url}/logs`,
            data: {
                query: createMortgageLogMutation,
                variables: variables
            },
            withCredentials: false,
            headers: { Authorization: `Bearer ${auth.token}` }
        })
            .then(response => {
                const log = response.data.data.create_mortgage_log;

                dispatch({
                    type: LOG_CREATE,
                    payload: log
                });

                resolve(log);
            })
            .catch(error => {
                reject(error);
                console.error("LOG_CREATE::FAIL", error);
            });
    });
};

/**
 * Soft deletes the log corresponding to the id param
 * * The function returns a Promise
 * * * then: void
 * * * catch: return error info
 * @param {Integer} id id of the log
 * @param {Object} auth auth object from the store
 */
export const deleteLog = (id, auth) => dispatch => {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${api.url}/logs`,
            withCredentials: false,
            data: {
                query: softDeleteLogMutation,
                variables: {
                    id: id
                }
            },
            headers: { Authorization: `Bearer ${auth.token}` }
        })
            .then(res => {
                const response = res.data.data.paranoid_delete_mortgage_log;

                dispatch({
                    type: LOG_DELETE,
                    payload: response
                });

                resolve(response);
            })
            .catch(error => {
                reject(error);

                console.error("LOG_DELETE::FAIL", error);
            });
    });
};

/**
 * deletes the element at the specified indexes, from the logs array
 * * The function returns a Promise
 * * * then: returns an updated logs array(without the deleted log)
 * * * catch: return error info
 * @param {Array} logs from the store
 * @param {Integer} parentIndex index of the parent element of the index(the table)
 * @param {Integer} logIndex the log index
 */
export const deleteLogLocal = (logs, parentIndex, logIndex) => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            const newLogs = _.cloneDeep(logs);

            newLogs[parentIndex].logs.splice(logIndex, 1);

            if (newLogs[parentIndex].logs.length === 0) {
                newLogs.splice(parentIndex, 1);
            }

            dispatch({
                type: LOG_DELETE_LOCAL,
                payload: newLogs
            });

            resolve(newLogs);
        } catch (error) {
            reject(error);

            console.error("LOG_DELETE_LOCAL::FAIL", error);
        }
    });
};

/**
 * removes the currently loaded log
 * * The function returns a Promise
 * * * then: void
 * * * catch: return error info
 */
export const unloadLog = () => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: LOG_UNLOAD,
                payload: null
            });

            resolve();
        } catch (error) {
            reject(error);

            console.error("LOG_UNLOAD::FAIL", error);
        }
    });
};

/**
 * Gets the log from for the id param
 * * The function returns a Promise
 * * * then: returns { log<Object> }
 * * * catch: return error info
 * @param {Integer} id id of the log
 * @param {Object} auth auth object from the store
 */
export const getLog = (id, auth) => dispatch => {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${api.url}/logs`,
            withCredentials: false,
            data: {
                query: getMortgageLogQuery,
                variables: {
                    id
                }
            },
            headers: { Authorization: `Bearer ${auth.token}` }
        })
            .then(response => {
                const log = response.data.data.mortgage_log;

                dispatch({
                    type: LOG_GET,
                    payload: log
                });

                resolve(log);
            })
            .catch(error => {
                reject(error);

                console.error("LOG_GET::FAIL", error);
            });
    });
};

/**
 * Gets the state for the last log, if there is any
 * * The function returns a Promise
 * * * then: returns { state<Object> }
 * * * catch: return error info
 * @param {Object} auth auth object from the store
 */
export const getLastLog = auth => dispatch => {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${api.url}/logs`,
            withCredentials: false,
            data: {
                query: lastMortgageLogQuery
            },
            headers: { Authorization: `Bearer ${auth.token}` }
        })
            .then(response => {
                const log = response.data.data.last_mortgage_log;

                dispatch({
                    type: LOG_GET_LAST_LOG,
                    payload: log
                });

                resolve(log);
            })
            .catch(error => {
                reject(error);
                console.error("LOG_GET_LAST_LOG::FAIL", error);
            });
    });
};

/**
 * gets the logs from the backend
 * * The function returns a Promise
 * * * then: returns {logs<Array>, lastCreatedLogId<Integer>, areLogsLoaded<Boolean>, firstLogId<Integer>, lastLogId<Integer>}
 * * * catch: return error info
 * @param {Object} logs logs object from the store
 * @param {Object} auth auth object from the store
 */
export const getLogs = (logs, offset, auth) => dispatch => {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${api.url}/logs`,
            withCredentials: false,
            data: {
                query: getLogsQuery,
                variables: {
                    offset,
                    limit: 15
                }
            },
            headers: { Authorization: `Bearer ${auth.token}` }
        })
            .then(res => {
                const response = res.data.data.mortgage_logs;

                const newLogs = _.cloneDeep(logs);

                response.forEach(log => {
                    const date = moment(log.created_at, "x").toDate();

                    let formatedDateFromLog = moment(log.created_at, "x").format("dddd DD MMM YYYY");

                    if (newLogs.length > 0 && formatedDateFromLog === newLogs[newLogs.length - 1].formatedDate) {
                        newLogs[newLogs.length - 1]["logs"].push(log);
                    } else {
                        newLogs.push({ formatedDate: formatedDateFromLog, date: date, logs: [log] });
                    }
                });

                const payload = {
                    logs: newLogs,
                    areLogsLoaded: true,
                    offset: offset + 15,
                    hasLoadedAllLogs: response.length === 0
                };

                dispatch({
                    type: LOG_LOAD_LOGS,
                    payload: payload
                });

                resolve(payload);
            })
            .catch(error => {
                reject(error);
                console.error("LOG_LOAD_LOGS::FAIL", error);
            });
    });
};
