import { Collapse, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";

import HistoryRow from "./HistoryRow";
import MobileHistoryRowTable from "./MobileHistoryRowTable";

import "../../Views/History/HistoryStyles.css";

function MobileHistoryRow(props) {
    const [expanded, setExpanded] = useState(null);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const { log, onClick, LastRowRef, isSelected, action, parentIndex, logIndex, classes } = props;

    return (
        <div
            className={classNames(classes.expand, {
                "mobile-history-row-selected": isSelected,
                "mobile-history-row-not-selected": !isSelected,
                "mobile-history-row-expanded": expanded,
                "mobile-history-row-not-expanded": !expanded
            })}
        >
            <div className={"mobile-history-row-content"}>
                <HistoryRow
                    key={log.id}
                    onClick={onClick}
                    log={log}
                    isSelected={isSelected}
                    parentIndex={parentIndex}
                    logIndex={logIndex}
                    LastRowRef={LastRowRef}
                    action={action}
                />
                <div>
                    <IconButton
                        className={classNames(classes.expand, {
                            [classes.expandOpen]: expanded
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </div>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div className="mobile-history-row-table-wrapper">
                    <MobileHistoryRowTable key={log.id} log={log} />
                </div>
            </Collapse>
        </div>
    );
}

const styles = theme => ({
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    }
});

export default withStyles(styles)(MobileHistoryRow);
