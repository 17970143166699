import { createStore, applyMiddleware } from "redux";
import think from "redux-thunk";
import rootReducer from "./Reducers";

const initialState = {};

const middleware = [think];

export const store = createStore(rootReducer, initialState, applyMiddleware(...middleware));

export const mapStateToProps = state => ({ auth: state.auth, menu: state.menu, logs: state.logs, lang: state.lang.language, css: state.css });

export const log = (title, message) => {
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    
    if (isChrome) {
        console.log(`%c${title}`, "background: #222; color: #bada55; border-radius: 4px; padding: 4px; border: 1px solid #bdbdbd", message);
    } else {
        console.log(title, message);
    }
};
