const ratesMaps = [
    { key: 40, value: "ltv_0_40" },
    { key: 45, value: "ltv_0_45" },
    { key: 50, value: "ltv_0_50" },
    { key: 54, value: "ltv_0_54" },
    { key: 55, value: "ltv_0_55" },
    { key: 60, value: "ltv_0_60" },
    { key: 61, value: "ltv_0_61" },
    { key: 65, value: "ltv_0_65" },
    { key: 66, value: "ltv_0_66" },
    { key: 67.5, value: "ltv_0_675" },
    { key: 70, value: "ltv_0_70" },
    { key: 71, value: "ltv_0_71" },
    { key: 75, value: "ltv_0_75" },
    { key: 80, value: "ltv_0_80" },
    { key: 81, value: "ltv_0_81" },
    { key: 85, value: "ltv_0_85" },
    { key: 88, value: "ltv_0_88" },
    { key: 90, value: "ltv_0_90" },
    { key: 91, value: "ltv_0_91" },
    { key: 95, value: "ltv_0_95" },
    { key: 100, value: "ltv_1_00" },
    { key: 101, value: "ltv_1_01" },
    { key: 102, value: "ltv_1_02" },
    { key: 103, value: "ltv_1_03" },
    { key: 104, value: "ltv_1_04" },
    { key: 105, value: "ltv_1_05" },
    { key: 106, value: "ltv_1_06" },
    { key: 108, value: "ltv_1_08" },
    { key: 110, value: "ltv_1_10" },
    { key: 112.5, value: "ltv_1_125" },
    { key: 115, value: "ltv_1_15" },
    { key: 120, value: "ltv_1_20" }
];

const nhgMap = [{ key: 90, value: "nhg90" }, { key: 90.01, value: "nhg" }];

export const getRateKey = (hasNHG, ltvValue) => {
    const list = hasNHG ? nhgMap : ratesMaps;

    for (let index = 0; index < list.length; index++) {
        const rate = list[index];
        if (ltvValue <= rate.key) {
            return rate.value;
        }
    }

    return list[list.length - 1].value;
};

export const calculateLTV = (mortgageAmount, valueHouse) => {
    if (mortgageAmount === 0 || mortgageAmount === "" || valueHouse === 0 || valueHouse === "") {
        return 0;
    }

    const value = Math.round((mortgageAmount / valueHouse) * 100);

    return value;
};

export const format = (number, lengthOfDecimal, lengthOfWholePart, sectionsDelimiter, decimalDelimiter) => {
    const re = "\\d(?=(\\d{" + (lengthOfWholePart || 3) + "})+" + (lengthOfDecimal > 0 ? "\\D" : "$") + ")";

    const num = parseFloat(number).toFixed(Math.max(0, ~~lengthOfDecimal));

    return (decimalDelimiter ? num.replace(".", decimalDelimiter) : num).replace(new RegExp(re, "g"), "$&" + (sectionsDelimiter || ","));
};
