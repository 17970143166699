import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";

export const BootstrapInput = withStyles(theme => ({
    root: {
        "label + &": {
            marginTop: theme.spacing.unit * 3
        }
    },
    input: {
        borderRadius: 4,
        position: "relative",
        border: "1px solid #ced4da",
        fontSize: 16,
        width: "auto",
        padding: "10px 26px 10px 12px",
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(","),
        "&:focus": {
            borderColor: theme.palette.primary.main,
            boxShadow: `0 0 0 1.5px ${theme.palette.primary.main}`
        }
    }
}))(InputBase);
