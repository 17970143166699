import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow, Checkbox, IconButton } from "@material-ui/core";
import { Warning, MoreVert, CheckCircle } from "@material-ui/icons";
import moment from "moment";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";
import "../../Views/History/HistoryStyles.css";

class HistoryRow extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    shouldComponentUpdate(prevProps, prevState) {
        const { lang } = this.props;

        return prevProps.isSelected !== this.props.isSelected || prevProps.lang !== lang;
    }

    changeTypeLanguage = value => {
        const { lang } = this.props;

        return value === "annuity"
            ? lang.dashboard.input_mortgage_type_part_value_annuity
            : lang.dashboard.input_mortgage_type_part_value_linear;
    };

    changeProductDescriptionLanguage = productDescription => {
        const { lang } = this.props;

        return productDescription === "Example mortgage" ? lang.dashboard.input_product_default_value : productDescription;
    };

    render() {
        const { log, onClick, LastRowRef, isSelected, action, parentIndex, logIndex, css } = this.props;

        return css.isMobile ? (
            // Mobile version
            <Fragment>
                <div ref={LastRowRef !== null ? LastRowRef : undefined}>
                    <Checkbox
                        color={"secondary"}
                        checked={isSelected}
                        onClick={event => {
                            onClick(event, parentIndex, logIndex, log.id);
                        }}
                    />
                </div>
                <div>{moment(log.created_at, "x").format("HH:mm")}</div>
                <div>
                    {log.input.mortgage_amount < log.calculations.max_mortgage_from_income &&
                    log.input.mortgage_amount <= log.input.value_house ? (
                        <CheckCircle className={"icon-check"} />
                    ) : (
                        <Warning className={"icon-warning"} />
                    )}
                </div>
                <div>{`€ ${log.input.mortgage_amount}`}</div>
            </Fragment>
        ) : (
            //Desktop Version
            <TableRow
                className={isSelected ? "history-row" : ""}
                hover
                onClick={event => {
                    onClick(event, parentIndex, logIndex, log.id);
                }}
                component={props => {
                    return (
                        <tr className={props.className} onClick={props.onClick} ref={LastRowRef !== null ? LastRowRef : undefined}>
                            <TableCell padding="checkbox">
                                <Checkbox color={"secondary"} checked={isSelected} />
                            </TableCell>
                            <TableCell align="left">{moment(log.created_at, "x").format("HH:mm")}</TableCell>
                            <TableCell align="left">
                                {log.input.mortgage_amount < log.calculations.max_mortgage_from_income &&
                                log.input.mortgage_amount <= log.input.value_house ? (
                                    <CheckCircle className={"icon-check"} />
                                ) : (
                                    <Warning className={"icon-warning"} />
                                )}
                            </TableCell>
                            <TableCell align="left">
                                {log.input.purchase_price_house !== null ? `€ ${log.input.purchase_price_house}` : "-"}
                            </TableCell>
                            <TableCell align="left">{`€ ${log.input.mortgage_amount}`}</TableCell>
                            <TableCell align="left">{log.input.parts.length}</TableCell>
                            <TableCell align="left">{`€ ${log.input.parts[0].mortgage_amount}`}</TableCell>
                            <TableCell align="left">{this.changeTypeLanguage(log.input.parts[0].mortgage_type)}</TableCell>
                            <TableCell align="left">{log.input.parts[0].interest_fixed_period}</TableCell>
                            <TableCell align="left">
                                {log.input.parts.length > 1 ? `€ ${log.input.parts[1].mortgage_amount}` : "-"}
                            </TableCell>
                            <TableCell align="left">
                                {log.input.parts.length > 1 ? this.changeTypeLanguage(log.input.parts[1].mortgage_type) : "-"}
                            </TableCell>
                            <TableCell align="left">
                                {log.input.parts.length > 1 ? log.input.parts[1].interest_fixed_period : "-"}
                            </TableCell>
                            <TableCell align="left">{this.changeProductDescriptionLanguage(log.product.description)}</TableCell>
                            <TableCell align="left">
                                <IconButton
                                    aria-owns={"menu"}
                                    aria-haspopup="true"
                                    onClick={event => {
                                        event.stopPropagation();
                                        if (action !== null) {
                                            action(event, parentIndex, logIndex, log.id);
                                        }
                                    }}
                                >
                                    <MoreVert />
                                </IconButton>
                            </TableCell>
                        </tr>
                    );
                }}
            />
        );
    }
}

HistoryRow.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    log: PropTypes.object.isRequired,
    parentIndex: PropTypes.number.isRequired,
    logIndex: PropTypes.number.isRequired,
    action: PropTypes.func,
    LastRowRef: PropTypes.object,
    onClick: PropTypes.func.isRequired
};

HistoryRow.defaultProps = {
    isSelected: false,
    action: null,
    LastRowRef: null
};

export default connect(mapStateToProps)(HistoryRow);
