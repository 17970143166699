import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip, Select, MenuItem, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";

import { mapStateToProps } from "../../../redux";
import { createLog } from "../../../Actions/logActions/actions";

import { CalculatorContext } from "../../../Contexts/CalculatorContext";

import { BootstrapInput } from "../../Inputs/BootstrapInput";

function StartDateDialog(props) {
    const context = useContext(CalculatorContext);

    const [newDate, setNewDate] = useState({});

    useEffect(() => {
        if (props.open) {
            const startDate = context.startDate.value.split("-");

            setNewDate({
                day: startDate[0],
                month: startDate[1],
                year: startDate[2]
            });
        }
    }, [props.open]);

    const handleMainAction = () => {
        const { onClose, createLog, auth } = props;
        const { day, month, year } = newDate;

        const { functions } = context;

        let newstartDate = context.startDate.value;

        context.startDate
            .setValue(day, month, year)
            .then(startDate => {
                newstartDate = startDate;
                return functions.requestCalculation();
            })
            .then(() => {
                onClose();
                return functions.requestMaxMortgageFromIncomeCalculation();
            })
            .then(newMaxMortgageIncome => {
                createLog(auth, {
                    ...context,
                    startDate: { ...context.startDate, value: newstartDate },
                    maxMortgageAmountIncome: newMaxMortgageIncome
                });
            });
    };

    const handleCancelAction = () => {
        props.onClose();
    };

    const handleMonthChange = event => {
        const newMonth = event.target.value;

        const currentDate = new Date();

        if (newMonth < currentDate.getMonth() + 1) {
            setNewDate({ ...newDate, month: newMonth, year: currentDate.getFullYear() + 1 });
        } else {
            setNewDate({ ...newDate, month: newMonth, year: currentDate.getFullYear() });
        }
    };

    const { open, lang, theme } = props;

    return (
        <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
            <DialogTitle>{lang.startDate.modal_title}</DialogTitle>
            <DialogContent className={"dialog-content"}>
                <div className={"content-wrapper"}>
                    <div className={"content-side"}>
                        <div className={"dialog-input"}>
                            <div className={"input-label"}>
                                <label>{lang.startDate.start_date_label}</label>
                            </div>
                            <div className={"input-help-wrapper"}>
                                <div style={{ display: "flex", width: "85%" }}>
                                    <div className={"input-field"} style={{ width: "20%", marginRight: 5 }}>
                                        <input value={newDate.day} readOnly={true} />
                                    </div>
                                    <Select
                                        style={{ width: "39%", marginRight: 5 }}
                                        value={newDate.month}
                                        onChange={handleMonthChange}
                                        input={<BootstrapInput name="month" id="month-input" />}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
                                            return (
                                                <MenuItem key={month} value={month}>
                                                    {month}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <div className={"input-field"} style={{ width: "39%" }}>
                                        <input value={newDate.year} readOnly={true} />
                                    </div>
                                </div>
                                <div className={"input-help"}>
                                    <Tooltip title={lang.startDate.start_date_help} disableFocusListener disableTouchListener>
                                        <Help style={{ color: theme.palette.secondary.main }} />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"content-side"}>
                        <Typography>{lang.startDate.start_date_explanation_par_1}</Typography>
                        <Typography>{lang.startDate.start_date_explanation_par_2}</Typography>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelAction} color="secondary">
                    {lang.startDate.button_cancel}
                </Button>
                <Button onClick={handleMainAction} color="secondary" variant={"contained"}>
                    {lang.startDate.button_apply}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

StartDateDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default connect(
    mapStateToProps,
    { createLog }
)(withTheme()(StartDateDialog));
