import { CircularProgress, Paper, Typography, IconButton, Collapse } from "@material-ui/core";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { Help } from "@material-ui/icons";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { XAxis, BarChart, Bar, LabelList, Tooltip, Area, AreaChart, LineChart, Line } from "recharts";
import classNames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MatTooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";

import { format } from "../../helperFunctions";
import { mapStateToProps } from "../../redux";
import ChartScrollButton from "./ChartScrollButton/ChartScrollButton";
import InformationDialog from "./InformationDialog/InformationDialog";

import "./ChartContainerStyles.css";

const MortgageAmountTooltip = ({ active, payload, infoDialog }) => {
    if (active) {
        if (infoDialog) {
            return <div />;
        }
        return (
            <Paper style={{ padding: "5px 10px" }}>
                {typeof payload[0].payload.values !== "undefined"
                    ? payload[0].payload.values.map((item, index) => {
                          return (
                              <p key={index} className="label">
                                  {item.value}
                              </p>
                          );
                      })
                    : undefined}
            </Paper>
        );
    }

    return null;
};

const InterestRateTooltip = ({ active, payload, coordinate, infoDialog }) => {
    if (active) {
        const values = payload[0].payload;
        if (infoDialog) {
            return <div />;
        }
        return (
            <Paper style={{ top: `${coordinate.y}px`, left: `${coordinate.x}px`, padding: "5px 10px", zIndex: 1000 }}>
                <p className="label">{`${values.name}: ${format(values.value, 2, 3, ".", ",")} %`}</p>
                {typeof values.monthlyValues !== "undefined"
                    ? values.monthlyValues.map((month, index) => {
                          return (
                              <p style={{ margin: 0, marginLeft: 5 }} key={index} className="label">
                                  {`${month.label}: ${month.value === null ? "-" : month.value}`}
                              </p>
                          );
                      })
                    : undefined}
            </Paper>
        );
    }

    return null;
};

const CustomizedLabel = props => {
    const { x, y, stroke, value } = props;

    return (
        <text x={x} y={y} dy={-10} fill={stroke} fontSize={14} textAnchor="middle">
            {format(value, 2, 3, ".", ",")}
        </text>
    );
};

class ChartContainer extends Component {
    static propTypes = {
        chartType: PropTypes.oneOf(["BarChart", "AreaChart", "LineChart"]),
        chartHeight: PropTypes.number,
        chartTotalTitle: PropTypes.string,
        chartTotalTooltip: PropTypes.string,
        chartData: PropTypes.array,
        chartColor: PropTypes.string,
        chartInfoDialog: PropTypes.bool,
        chartTooltip: PropTypes.string,
        chartTooltipType: PropTypes.oneOf(["MortgageTooltip", "InterestRateTooltip"]),
        isChartDataLoading: PropTypes.bool.isRequired,
        totals: PropTypes.array,
        totalsHeader: PropTypes.string,
        isTotalValueWholeNumber: PropTypes.bool
    };

    static defaultProps = {
        chartData: [],
        chartTotalTitle: "chart title",
        chartHeight: 200,
        chartColor: null,
        chartTotalTooltip: null,
        chartTooltip: null,
        isChartDataLoading: false,
        chartInfoDialog: false,
        chartType: "BarChart",
        chartTooltipType: "MortgageTooltip",
        isTotalValueWholeNumber: true
    };

    constructor(props) {
        super(props);

        this.chartDomRef = React.createRef();

        this.totalsRef = React.createRef();

        this.state = {
            chartWidth: this.calculateWidth(),
            shouldShowScrollAxisButtons: this.shouldShowScrollAxisButtons(),
            mousePosition: null,
            isInfoDialogOpen: false,
            infoDialogPayload: { activePayload: [] },
            expandedChart: false,
            expandedTotal: false
        };

        this.updateChart = this._updateChartDimensions.bind(this);

        this.debounceUpdate = debounce(this.updateChart, 300);
    }

    componentDidMount() {
        window.addEventListener("resize", this.debounceUpdate, false);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { isChartDataLoading, chartData, menu, lang } = this.props;
        const { chartWidth, isInfoDialogOpen, expandedChart, expandedTotal } = this.state;

        return (
            chartWidth !== nextState.chartWidth ||
            isChartDataLoading !== nextProps.isChartDataLoading ||
            chartData !== nextProps.chartData ||
            menu.isOpen !== nextProps.menu.isOpen ||
            nextState.isInfoDialogOpen !== isInfoDialogOpen ||
            lang !== nextProps.lang ||
            expandedChart !== nextProps.expandedChart ||
            expandedTotal !== nextProps.expandedTotal
        );
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.debounceUpdate, false);
    }

    componentDidUpdate(prevProps) {
        const { menu, chartData } = this.props;
        if (menu.isOpen !== prevProps.menu.isOpen || chartData !== prevProps.chartData) {
            this._updateChartDimensions();
        }
    }

    openInfoDialog = payload => {
        if (payload !== null) {
            this.setState({ isInfoDialogOpen: true, infoDialogPayload: payload });
        }
    };

    closeInfoDialog = () => {
        this.setState({ isInfoDialogOpen: false });
    };

    _updateChartDimensions() {
        this.setState({ chartWidth: this.calculateWidth(), shouldShowScrollAxisButtons: this.shouldShowScrollAxisButtons() });
    }

    calculateWidth = () => {
        const { menu } = this.props;

        const width =
            document.body.clientWidth < 1600
                ? 1500
                : document.body.clientWidth - (menu.isOpen ? menu.menuOpenWidth : menu.menuCloseWidth) - 60;

        return width;
    };

    shouldShowScrollAxisButtons = () => {
        const { totals } = this.props;

        const shouldShowScrollAxisButtons = typeof totals !== "undefined" ? true : false;

        const shouldShowScrollAxisButtonsDependingOnWidth = document.body.clientWidth < 1600 ? true : false;

        return shouldShowScrollAxisButtons || shouldShowScrollAxisButtonsDependingOnWidth;
    };

    handleExpandClickChart = () => {
        this.setState({
            expandedChart: !this.state.expandedChart
        });
    };

    handleExpandClickTotal = () => {
        this.setState({
            expandedTotal: !this.state.expandedTotal
        });
    };

    render() {
        const {
            chartData,
            isChartDataLoading,
            chartType,
            totals,
            totalsHeader,
            isTotalValueWholeNumber,
            chartColor,
            chartTooltip,
            chartTooltipType,
            chartTotalTitle,
            chartHeight,
            chartInfoDialog,
            chartTotalTooltip,
            lang,
            classes,
            css
        } = this.props;
        const { chartWidth, shouldShowScrollAxisButtons, expandedTotal, expandedChart } = this.state;
        const mainColor = this.props.theme.palette.primary.main;

        return isChartDataLoading ? (
            <Paper className={"chart-wrapper chart-loader"}>
                <CircularProgress />
            </Paper>
        ) : chartData.length > 1 ? (
            <div className={"chart-container"}>
                <Paper className={"chart-wrapper"} style={{ width: "100%", margin: 0 }}>
                    <div className={"chart-title"}>
                        <div style={{ display: "flex" }}>
                            <Typography variant={"h1"}>{chartTotalTitle}</Typography>
                            {chartTooltip !== null ? (
                                <MatTooltip title={chartTooltip} disableFocusListener disableTouchListener>
                                    <Help style={{ color: this.props.theme.palette.secondary.main }} />
                                </MatTooltip>
                            ) : (
                                undefined
                            )}
                        </div>
                        <div className={"check-block-icon-button"}>
                            <IconButton
                                className={classNames(classes.expand, {
                                    [classes.expandOpen]: expandedChart
                                })}
                                onClick={this.handleExpandClickChart}
                                aria-expanded={expandedChart}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>
                    </div>
                    <Collapse in={css.isMobile ? expandedChart : true} timeout="auto">
                        <div className={"chart"} ref={this.chartDomRef}>
                            {chartType === "BarChart" ? (
                                <BarChart
                                    width={chartWidth}
                                    height={chartHeight}
                                    data={chartData}
                                    margin={{ top: 50, right: 20, bottom: 0, left: 20 }}
                                    onClick={event => {
                                        if (chartInfoDialog) {
                                            this.openInfoDialog(event);
                                        }
                                    }}
                                >
                                    <XAxis dataKey="name" interval={"preserveStartEnd"} />
                                    <Bar
                                        name={chartData.length > 1 ? chartData[0].label : ""}
                                        dataKey="value"
                                        fill={chartColor == null ? mainColor : chartColor}
                                    >
                                        <LabelList dataKey="value" position="top" />
                                    </Bar>
                                </BarChart>
                            ) : chartType === "LineChart" ? (
                                <LineChart
                                    width={chartWidth}
                                    height={chartHeight}
                                    data={chartData}
                                    margin={{ top: 50, right: 20, bottom: 0, left: 40 }}
                                    onClick={event => {
                                        if (chartInfoDialog) {
                                            this.openInfoDialog(event);
                                        }
                                    }}
                                >
                                    <Tooltip
                                            disableFocusListener
                                            disableTouchListener
                                        content={
                                            chartTooltipType === "MortgageTooltip" ? (
                                                <MortgageAmountTooltip infoDialog={chartInfoDialog} />
                                            ) : (
                                                <InterestRateTooltip infoDialog={chartInfoDialog} />
                                            )
                                        }
                                    />

                                    <Line
                                        type="monotone"
                                        dataKey="value"
                                        stroke={chartColor == null ? mainColor : chartColor}
                                        label={<CustomizedLabel />}
                                    />
                                    <XAxis dataKey="name" interval={"preserveStartEnd"} />
                                </LineChart>
                            ) : (
                                <AreaChart
                                    width={chartWidth}
                                    height={chartHeight}
                                    data={chartData}
                                    margin={{ top: 50, right: 20, bottom: 0, left: 20 }}
                                    onClick={event => {
                                        if (chartInfoDialog) {
                                            this.openInfoDialog(event);
                                        }
                                    }}
                                >
                                    <Tooltip disableFocusListener disableTouchListener
                                        content={
                                            chartTooltipType === "MortgageTooltip" ? (
                                                <MortgageAmountTooltip infoDialog={chartInfoDialog} />
                                            ) : (
                                                <InterestRateTooltip infoDialog={chartInfoDialog} />
                                            )
                                        }
                                    />

                                    <Area
                                        type="monotone"
                                        name={chartData.length > 1 ? chartData[0].label : ""}
                                        dataKey="value"
                                        stroke={chartColor == null ? mainColor : chartColor}
                                        fill={chartColor == null ? mainColor : chartColor}
                                    />
                                </AreaChart>
                            )}
                        </div>
                        {shouldShowScrollAxisButtons ? (
                            <Fragment>
                                <ChartScrollButton chartDomRef={this.chartDomRef} orientation={"normal"} />
                                <ChartScrollButton chartDomRef={this.chartDomRef} orientation={"reversed"} />
                            </Fragment>
                        ) : null}
                    </Collapse>
                </Paper>
                {typeof totals !== "undefined" ? (
                    <div className={"totals-container"} ref={this.totalsRef}>
                        <Paper className={"totals-paper"}>
                            <div className={"totals-header"}>
                                <div style={{ display: "flex" }}>
                                    <Typography variant={"h1"} style={{ marginRight: "5px" }}>
                                        {totalsHeader}
                                    </Typography>
                                    {chartTotalTooltip !== null ? (
                                        <MatTooltip title={chartTotalTooltip} disableFocusListener disableTouchListener>
                                            <Help style={{ color: this.props.theme.palette.secondary.main }} />
                                        </MatTooltip>
                                    ) : (
                                        undefined
                                    )}
                                </div>
                                <div className={"check-block-icon-button"}>
                                    <IconButton
                                        className={classNames(classes.expand, {
                                            [classes.expandOpen]: expandedTotal
                                        })}
                                        onClick={this.handleExpandClickTotal}
                                        aria-expanded={expandedTotal}
                                        aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <Collapse in={css.isMobile ? expandedTotal : true} timeout="auto">
                                <div className={"totals-item"}>
                                    <Typography variant={"h2"}>{lang.dashboard.total_net_interest_fixed_period_label}</Typography>
                                    <Typography>
                                        {isTotalValueWholeNumber
                                            ? `€ ${format(totals[0], 0, 3, ".", ",")}`
                                            : `${format(totals[0], 2, 3, ".", ",")} %`}
                                    </Typography>
                                </div>
                                <div className={"totals-item"}>
                                    <Typography variant={"h2"}>{lang.dashboard.total_net_duration_label}</Typography>
                                    <Typography>
                                        {isTotalValueWholeNumber
                                            ? `€ ${format(totals[1], 0, 3, ".", ",")}`
                                            : `${format(totals[1], 2, 3, ".", ",")} %`}
                                    </Typography>
                                </div>
                            </Collapse>
                        </Paper>
                    </div>
                ) : (
                    undefined
                )}
                {chartInfoDialog ? (
                    <InformationDialog
                        payload={this.state.infoDialogPayload}
                        isOpen={this.state.isInfoDialogOpen}
                        mainAction={this.closeInfoDialog}
                    />
                ) : (
                    undefined
                )}
            </div>
        ) : null;
    }
}

const styles = theme => ({
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        }),
        padding: "0"
    },
    expandOpen: {
        transform: "rotate(180deg)"
    }
});

export default connect(
    mapStateToProps,
    {}
)(withTheme()(withStyles(styles)(ChartContainer)));
