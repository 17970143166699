import React from "react";
import Template from "../../Components/Template/Template";
import { Button } from "@material-ui/core";
import example from "./../../Images/image_graphs_home_page.png"; // Tell Webpack this JS file uses this image
import logo from "../../Images/logo_hyponav_beta.svg";

export default function Home(props) {
    const redirectToDashboard = () => {
        props.history.push("/dashboard");
    };

    return (
        <Template shouldIncludeSideBar={false} contentFullHeight={true} history={props.history}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", flexDirection: "column" }}>
                <img src={logo} alt="Logo" style={{ width: "300px", margin: "20px 0" }} />
                <img src={example} style={{ maxWidth: 1000, margin: "40px 0" }} alt="example result" />
                <Button color="secondary" variant={"contained"} onClick={redirectToDashboard}>
                    start
                </Button>
            </div>
        </Template>
    );
}
