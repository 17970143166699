import { connect } from "react-redux";
import * as axios from "axios";
import React, { useEffect } from "react";

import { authVerifyEmail } from "../../Actions/authActions/actions";
import { mapStateToProps } from "../../redux";

axios.defaults.withCredentials = true;

function VerifyEmail(props) {
    const extractToken = token => {
        return token.replace("?token=", "");
    };

    useEffect(() => {
        props.authVerifyEmail(extractToken(props.location.search));
    }, []);

    return <div />;
}

export default connect(
    mapStateToProps,
    { authVerifyEmail }
)(VerifyEmail);
