import React, { Component } from "react";
import classNames from "classnames";
import { CalculatorContext } from "./../../Contexts/CalculatorContext";
import { withStyles } from "@material-ui/core/styles";
import Template from "../../Components/Template/Template";
import { Delete, Info } from "@material-ui/icons";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Typography,
    CircularProgress,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Menu,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@material-ui/core";
import MobileHistoryRow from "./../../Components/HistoryRow/MobileHistoryRow";
import BottomSelectionLogBar from "./../../Components/BottomSelectionLogBar/BottomSelectionLogBar";
import HistoryRow from "../../Components/HistoryRow/HistoryRow";
import Draggable from "react-draggable";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";
import { getLogs, getLog, deleteLogLocal, deleteLog } from "../../Actions/logActions/actions";
import "./HistoryStyles.css";

class History extends Component {
    static contextType = CalculatorContext;

    constructor(props) {
        super(props);

        this.lastLog = React.createRef();

        this.state = {
            menu: { anchorEl: null, logParentIndex: null, logIndex: null, logID: null },
            selectedLog: { element: null, logParentIndex: null, logIndex: null, logID: 0 },
            loadingOlderLogs: false,
            infoDialog: { isOpen: false, content: null },
            isDeleteDialogOpen: false,
            top: 0,
            height: 0,
            scroll: 0
        };
    }

    componentDidMount() {
        const { logs, auth, getLogs, css } = this.props;

        if (logs.logs.length === 0) {
            getLogs(logs.logs, logs.offset, auth);
        }

        window.addEventListener("scroll", this.scrollEvent);

        if (css.isMobile) {
            const el = document.querySelector(".secont-header");
            this.setState({ top: el.offsetTop, height: el.offsetHeight });
        }
    }

    selectLog = (event, parentIndex, logIndex, logID) => {
        const { selectedLog } = this.state;

        const newObject =
            selectedLog.logID === logID
                ? { element: null, logParentIndex: null, logIndex: null, logID: null }
                : { element: event.currentTarget, logParentIndex: parentIndex, logIndex: logIndex, logID: logID };

        this.setState({ selectedLog: newObject });
    };

    disSelectLog = callback => {
        this.setState(
            {
                selectedLog: { element: null, logParentIndex: null, logIndex: null, logID: null }
            },
            () => {
                if (typeof callback !== "undefined") {
                    callback();
                }
            }
        );
    };

    openInformationDialog = () => {
        const { logs } = this.props;

        const { menu } = this.state;

        this.setState({ infoDialog: { isOpen: true, content: logs.logs[menu.logParentIndex].logs[menu.logIndex] } });
    };

    closeInformationDialog = () => {
        this.setState({ infoDialog: { isOpen: false, content: null } });
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollEvent);
    }

    scrollEvent = () => {
        if (this.lastLog.current !== null && !this.state.loadingOlderLogs && !this.props.logs.hasLoadedAllLogs) {
            const windowHeight = window.innerHeight;

            if (window.scrollY + windowHeight > this.offsetEl(this.lastLog.current).top) {
                this.setState({ loadingOlderLogs: true }, () => {
                    const { auth, logs, getLogs } = this.props;

                    getLogs(logs.logs, logs.offset, auth).then(() => {
                        this.setState({ loadingOlderLogs: false });
                    });
                });
            }
        }

        this.setState({ scroll: window.scrollY });
    };

    offsetEl = el => {
        const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    };

    loadLog = () => {
        const itemId = this.state.selectedLog.logID;

        if (this.props.currentLog !== null) {
            this.context.state.rollback().then(() => {
                this.requestLog(itemId);
            });
        } else {
            this.requestLog(itemId);
        }
    };

    requestLog = itemId => {
        const { auth, getLog, history } = this.props;

        getLog(itemId, auth).then(() => {
            this.context.state.checkPoint().then(() => {
                history.push("/dashboard");
            });
        });
    };

    closeSelectionBar = () => {
        console.log("closeSelectionBar");
    };

    handleOpenMenu = (event, parentIndex, logIndex, logID) => {
        this.setState({ menu: { anchorEl: event.currentTarget, logParentIndex: parentIndex, logIndex: logIndex, logID: logID } });
    };

    handleCloseMenu = () => {
        this.setState({ menu: { anchorEl: null, logParentIndex: null, logIndex: null, logID: null } });
    };

    deleteLog = () => {
        const { logs, auth, deleteLogLocal, deleteLog } = this.props;
        const { menu, selectedLog } = this.state;

        deleteLogLocal(logs.logs, menu.logParentIndex, menu.logIndex).then(() => {
            if (selectedLog.logID !== null) {
                if (menu.logID === selectedLog.logID) {
                    this.disSelectLog();
                }
            }
            deleteLog(menu.logID, auth);
        });

        this.handleCloseMenu();
    };

    deleteLogFromSelectionLogBar = () => {
        const { logs, auth, deleteLogLocal, deleteLog } = this.props;
        const { selectedLog } = this.state;

        deleteLogLocal(logs.logs, selectedLog.logParentIndex, selectedLog.logIndex).then(() => {
            deleteLog(selectedLog.logID, auth);
            this.disSelectLog();
        });

        this.handleCloseMenu();
    };

    openDeleteDialog = () => {
        this.setState({ isDeleteDialogOpen: true });
    };

    closeDeleteDialog = () => {
        this.setState({ isDeleteDialogOpen: false });
    };

    render() {
        const { logs, history, lang, classes, css } = this.props;
        const { menu, isDeleteDialogOpen } = this.state;

        return (
            <Template title={lang.history.title_tag} shouldIncludeSideBar={true} history={history} contentFullHeight={!logs.areLogsLoaded}>
                <div
                    className={classNames("dom history-dom", {
                        "history-loader": !logs.areLogsLoaded
                    })}
                >
                    {!logs.areLogsLoaded ? (
                        <CircularProgress />
                    ) : logs.logs.length > 0 ? (
                        logs.logs.map((logsForDays, index) => {
                            const today = new Date();

                            let formatedDate = logsForDays.formatedDate;

                            if (logsForDays.date.getDate() === today.getDate()) {
                                formatedDate = `${lang.history.date_prefix_today} - ` + formatedDate;
                            } else if (Math.abs(logsForDays.date.getDate() - today.getDate()) === 1) {
                                formatedDate = `${lang.history.date_prefix_yesterday} - ` + formatedDate;
                            }

                            return (
                                <div className={"history-day"} key={index}>
                                    {css.isMobile ? (
                                        <Paper
                                            style={
                                                this.state.scroll > this.state.top ? { top: "85px", display: "block" } : { display: "none" }
                                            }
                                            classes={this.state.scroll > this.state.top ? { root: classes.tableHeader } : null}
                                        >
                                            <div className={"header-cell"}>{lang.history.column_select_header}</div>
                                            <div className={"header-cell"}>{lang.history.column_time_header}</div>
                                            <div className={"header-cell"}>{lang.history.column_check_header}</div>
                                            <div className={"header-cell"}>{lang.history.column_mortgage_amount_header}</div>
                                        </Paper>
                                    ) : null}
                                    <Typography
                                        classes={{ root: classes.titleText }}
                                        style={{ marginTop: index === 0 ? 20 : 40, marginBottom: 10 }}
                                        variant={"h5"}
                                    >
                                        {formatedDate}
                                    </Typography>
                                    <Paper classes={{ root: classes.paper }}>
                                        {css.isMobile ? (
                                            // Mobile version
                                            <div className={"table-mobile"}>
                                                <div className={"header-row"}>
                                                    <Paper>
                                                        <div className={"header-cell"}>{lang.history.column_select_header}</div>
                                                        <div className={"header-cell"}>{lang.history.column_time_header}</div>
                                                        <div className={"header-cell"}>{lang.history.column_check_header}</div>
                                                        <div className={"header-cell"}>{lang.history.column_mortgage_amount_header}</div>
                                                    </Paper>
                                                </div>
                                                <div className={"row-body"}>
                                                    {logsForDays.logs.map((log, logIndex) => {
                                                        return (
                                                            <MobileHistoryRow
                                                                key={log.id}
                                                                onClick={this.selectLog}
                                                                log={log}
                                                                isSelected={this.state.selectedLog.logID === log.id}
                                                                parentIndex={index}
                                                                logIndex={logIndex}
                                                                LastRowRef={
                                                                    typeof logs.logs !== "undefined" &&
                                                                    typeof logsForDays.logs !== "undefined"
                                                                        ? logs.logs.length - 1 === index &&
                                                                          logIndex === logsForDays.logs.length - 1
                                                                            ? this.lastLog
                                                                            : null
                                                                        : null
                                                                }
                                                                action={this.handleOpenMenu}
                                                            ></MobileHistoryRow>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ) : (
                                            // Desktop version
                                            <Table className="history-table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className={"smaller-item"}>
                                                            {lang.history.column_select_header}
                                                        </TableCell>
                                                        <TableCell className={"smaller-item"}>{lang.history.column_time_header}</TableCell>
                                                        <TableCell className={"smaller-item"}>{lang.history.column_check_header}</TableCell>
                                                        <TableCell className={"small-item"} component="th" align="left">
                                                            {lang.history.column_house_header}
                                                        </TableCell>
                                                        <TableCell className={"small-item"} component="th" align="left">
                                                            {lang.history.column_mortgage_amount_header}
                                                        </TableCell>
                                                        <TableCell className={"smaller-item"} component="th" align="left">
                                                            {lang.history.column_parts_header}
                                                        </TableCell>
                                                        <TableCell className={"small-item"} component="th" align="left">
                                                            {lang.history.column_part_1_amount_header}
                                                        </TableCell>
                                                        <TableCell className={"smaller-item"} component="th" align="left">
                                                            {lang.history.column_part_1_type_header}
                                                        </TableCell>
                                                        <TableCell className={"smaller-item"} component="th" align="left">
                                                            {lang.history.column_part_1_interest_fixed_period_header}
                                                        </TableCell>
                                                        <TableCell className={"small-item"} component="th" align="left">
                                                            {lang.history.column_part_2_amount_header}
                                                        </TableCell>
                                                        <TableCell className={"smaller-item"} component="th" align="left">
                                                            {lang.history.column_part_2_type_header}
                                                        </TableCell>
                                                        <TableCell className={"smaller-item"} component="th" align="left">
                                                            {lang.history.column_part_2_interest_fixed_period_header}
                                                        </TableCell>
                                                        <TableCell className={"large-item"} component="th" align="left">
                                                            {lang.history.column_product_header}
                                                        </TableCell>
                                                        <TableCell className={"smaller-item"} component="th" align="left" />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className={"table-body"}>
                                                    {logsForDays.logs.map((log, logIndex) => {
                                                        return (
                                                            <HistoryRow
                                                                key={log.id}
                                                                onClick={this.selectLog}
                                                                log={log}
                                                                isSelected={this.state.selectedLog.logID === log.id}
                                                                parentIndex={index}
                                                                logIndex={logIndex}
                                                                LastRowRef={
                                                                    typeof logs.logs !== "undefined" &&
                                                                    typeof logsForDays.logs !== "undefined"
                                                                        ? logs.logs.length - 1 === index &&
                                                                          logIndex === logsForDays.logs.length - 1
                                                                            ? this.lastLog
                                                                            : null
                                                                        : null
                                                                }
                                                                action={this.handleOpenMenu}
                                                            />
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        )}
                                    </Paper>
                                </div>
                            );
                        })
                    ) : (
                        <Typography variant={"subtitle1"}>No history.</Typography>
                    )}
                </div>
                {this.state.selectedLog.element !== null ? <div style={{ marginTop: 40 }} /> : undefined}
                <BottomSelectionLogBar
                    selectedLog={this.state.selectedLog.element}
                    primaryAction={this.loadLog}
                    primaryActionText={lang.history.bar_calculation_selected_button_view}
                    secondaryAction={this.openDeleteDialog}
                    secondaryActionText={lang.history.bar_calculation_selected_button_remove}
                    closeAction={this.disSelectLog}
                />
                <Menu anchorEl={menu.anchorEl} id={"menu"} open={Boolean(menu.anchorEl)} onClose={this.handleCloseMenu}>
                    <MenuItem onClick={this.openInformationDialog}>
                        <ListItemIcon>
                            <Info />
                        </ListItemIcon>
                        <ListItemText inset primary="Info" />
                    </MenuItem>
                    <MenuItem onClick={this.openDeleteDialog}>
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText inset primary="Delete" />
                    </MenuItem>
                </Menu>
                <Dialog fullWidth={true} maxWidth={"md"} open={this.state.infoDialog.isOpen}>
                    <DialogTitle>Log information</DialogTitle>
                    <DialogContent>asdasda</DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={this.closeInformationDialog}>
                            ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isDeleteDialogOpen}
                    onClose={this.closeDeleteDialog}
                    PaperComponent={props => {
                        return (
                            <Draggable>
                                <Paper {...props} />
                            </Draggable>
                        );
                    }}
                    aria-labelledby="draggable-delete-dialog"
                >
                    <DialogTitle id="draggable-delete-dialog">Delete log</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete this log?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDeleteDialog} color="secondary">
                            {lang.history.bar_calculation_loaded_title_button_close}
                        </Button>
                        <Button
                            onClick={() => {
                                if (this.state.menu.anchorEl !== null) {
                                    this.deleteLog();
                                } else {
                                    this.deleteLogFromSelectionLogBar();
                                }
                                this.closeDeleteDialog();
                            }}
                            variant="contained"
                            color="secondary"
                        >
                            {lang.history.bar_calculation_selected_button_remove}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Template>
        );
    }
}

const styles = theme => ({
    cardContent: {
        width: "100%",
        padding: "0 !important"
    },
    paper: {
        [theme.breakpoints.down(767)]: {
            width: "100%"
        }
    },
    titleText: {
        [theme.breakpoints.down(767)]: {
            fontSize: "1.2rem"
        }
    },
    tableHeader: {
        position: "fixed",
        width: "90%",
        zIndex: "2"
    }
});

export default connect(
    mapStateToProps,
    { getLogs, getLog, deleteLogLocal, deleteLog }
)(withStyles(styles)(History));
