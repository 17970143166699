import { AUTH_VERIFY_EMAIL, AUTH_SIGN_OUT, AUTH_SIGN_IN_DEFAULT, AUTH_SIGN_UP_DEFAULT, AUTH_VERIFY_TOKEN, AUTH_CHANGE_ROLE } from "./types";
import axios from "axios";

import { api, origin } from "../../services";

/**
 * Verifys the email.
 */
export const authVerifyToken = () => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            axios({
                method: "get",
                withCredentials: true,
                url: `${api.url}/auth/verify/token`
            })
                .then(response => {
                    getUserData(response.data.data.token)
                        .then(user => {
                            const payload = {
                                token: response.data.data.token,
                                user: { ...user, role: response.data.data.role }
                            };

                            dispatch({
                                type: AUTH_VERIFY_TOKEN,
                                payload: payload
                            });

                            resolve(payload);
                        })
                        .catch(() => {
                            dispatch({
                                type: AUTH_VERIFY_TOKEN,
                                payload: { token: null, user: null }
                            });
                            resolve({ token: null, user: null });
                        });
                })
                .catch(() => {
                    dispatch({
                        type: AUTH_VERIFY_TOKEN,
                        payload: { token: null, user: null }
                    });
                    resolve({ token: null, user: null });
                });
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Verifys the email.
 */
export const authVerifyEmail = token => dispatch => {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            withCredentials: true,
            url: `${api.url}/auth/verify`,
            data: {
                verify_email_token: token
            }
        })
            .then(response => {
                getUserData(response.data.data.token)
                    .then(user => {
                        const payload = {
                            token: response.data.data.token,
                            user: { ...user, role: response.data.data.role }
                        };
                        dispatch({
                            type: AUTH_VERIFY_EMAIL,
                            payload: payload
                        });
                        resolve(payload);
                    })
                    .catch(error => {
                        reject(error);
                        console.error("AUTH_GET_USER_DATA::FAIL", error);
                    });
            })
            .catch(error => {
                reject(error);
                console.error("AUTH_VERIFY_EMAIL::FAIL", error.response);
            });
    });
};

const getUserData = token => {
    return new Promise((resolve, reject) => {
        try {
            axios({
                method: "post",
                url: `${api.url}/resources`,
                data: {
                    query: `query{
                    user{
                        first_name
                        last_name
                        photo
                    }
                }`
                },
                withCredentials: false,
                headers: { Authorization: `Bearer ${token}` }
            })
                .then(response => {
                    const res = {
                        ...response.data.data.user,
                        displayName: `${response.data.data.user.first_name}${
                            response.data.data.user.last_name !== null ? ` ${response.data.data.user.last_name}` : ""
                        }`
                    };
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                    console.error("AUTH_GET_USER_DATA::FAIL", err.response);
                });
        } catch (error) {
            reject(error);
        }
    });
};
/**
 * default sign in function
 * * The function returns a Promise
 * * * then: user<Object>
 * * * catch: return error<Object>
 * @param {Object} userProps user properties, syntax: {email:<email>, password:<password>}
 */
export const signInDefault = (userProps = {}) => dispatch => {
    return new Promise((resolve, reject) => {
        //terminate if the props are missing and call the fail callback
        if (typeof userProps.email === "undefined" || typeof userProps.password === "undefined") {
            reject({ code: "auth/missing-credentials", message: "Some of the credentials are missing" });
        } else {
            axios({
                method: "post",
                url: `${api.url}/auth/login`,
                data: {
                    email: userProps.email,
                    password: userProps.password
                }
            })
                .then(response => {
                    getUserData(response.data.data.token)
                        .then(user => {
                            const payload = {
                                token: response.data.data.token,
                                user: { ...user, role: response.data.data.role }
                            };
                            dispatch({
                                type: AUTH_SIGN_IN_DEFAULT,
                                payload: payload
                            });
                            resolve(payload);
                        })
                        .catch(error => {
                            console.error("AUTH_GET_USER_DATA::FAIL", error);
                        });
                })
                .catch(error => {
                    reject(error);

                    console.error("AUTH_SIGN_IN_DEFAULT::FAIL", error.response);
                });
        }
    });
};

/**
 * default sign in function
 * * The function returns a Promise
 * * * then: user<Object>
 * * * catch: return error<Object>
 * @param {Object} userProps user properties, syntax: {email:<email>, password:<password>, userName:<userName>}
 */
export const signUpDefault = (userProps = {}) => dispatch => {
    return new Promise((resolve, reject) => {
        //terminate if the props are missing and call the fail callback
        if (
            typeof userProps.email === "undefined" ||
            typeof userProps.password === "undefined" ||
            typeof userProps.userName === "undefined" ||
            typeof userProps.confirmPassword === "undefined"
        ) {
            reject({ code: "auth/missing-credentials", message: "Some of the credentials are missing" });
        } else {
            axios({
                method: "post",
                url: `${api.url}/auth/register`,
                data: {
                    firstName: userProps.firstName,
                    email: userProps.email,
                    password: userProps.password,
                    confirm_password: userProps.confirmPassword,
                    role: userProps.role,
                    // companyName: userProps.companyName,
                    url: `${origin.url}/verify`
                }
            })
                .then(response => {
                    dispatch({
                        type: AUTH_SIGN_UP_DEFAULT,
                        payload: response.data.data
                    });

                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data.error);
                    console.error("AUTH_SIGN_UP_DEFAULT::FAIL", error);
                });
        }
    });
};

/**
 * default sign out function
 * * The function returns a Promise
 * * * then: void
 * * * catch: return error<Object>
 */
export const signOut = () => dispatch => {
    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: `${api.url}/auth/logout`
        })
            .then(function() {
                dispatch({
                    type: AUTH_SIGN_OUT,
                    payload: null
                });

                resolve();
            })
            .catch(error => {
                reject(error);

                console.error("AUTH_SIGN_OUT::FAIL", error.response);
            });
    });
};

export const changeRole = role => dispatch => {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: `${api.url}/auth/role`,
            data: { role: role }
        })
            .then(res => {
                dispatch({
                    type: AUTH_CHANGE_ROLE,
                    payload: { token: res.data.data.token, role: res.data.data.role }
                });

                resolve();
            })
            .catch(error => {
                reject(error);

                console.error("AUTH_CHANGE_ROLE::FAIL", error.response);
            });
    });
};
