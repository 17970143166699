import React, { Component, Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core/styles";
import { IconButton, Tooltip, Button, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";
import "./BottomSelectionLogBarStyles.css";

class BottomSelectionLogBar extends Component {
    static propTypes = {
        selectedLog: PropTypes.object,
        text: PropTypes.string,
        closeAction: PropTypes.func.isRequired,
        closeActionText: PropTypes.string,
        secondaryAction: PropTypes.func,
        secondaryActionText: PropTypes.string,
        primaryAction: PropTypes.func,
        primaryActionText: PropTypes.string
    };

    static defaultProps = {
        closeActionText: null,
        secondaryActionText: null,
        text: null,
        primaryActionText: null
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    shouldComponentUpdate(prevProps, prevState) {
        const { selectedLog, menu, lang } = this.props;

        return prevProps.selectedLog !== selectedLog || menu.isOpen !== prevProps.menu.isOpen || lang !== prevProps.lang;
    }

    render() {
        const {
            menu,
            selectedLog,
            closeAction,
            primaryAction,
            primaryActionText,
            secondaryAction,
            secondaryActionText,
            closeActionText,
            text,
            lang,
            css
        } = this.props;

        return selectedLog !== null ? (
            <div
                className={classNames("bottom-selection-app-bar", {
                    "side-menu-open-animation": !menu.isOpen,
                    "side-menu-close-animation": menu.isOpen
                })}
                style={
                    css.isMobile
                        ? {
                              backgroundColor: this.props.theme.palette.primary.main,
                              color: this.props.theme.palette.primary.contrastText
                          }
                        : {
                              width: menu.isOpen ? `calc(100% - ${menu.menuOpenWidth}px)` : `calc(100% - ${menu.menuCloseWidth}px)`,
                              backgroundColor: this.props.theme.palette.primary.main,
                              color: this.props.theme.palette.primary.contrastText,
                              marginLeft: menu.isOpen ? menu.menuOpenWidth : menu.menuCloseWidth
                          }
                }
            >
                <div className={"bottom-selection-content"}>
                    <Tooltip
                        title={closeActionText !== null ? closeActionText : "Close"}
                        aria-label={closeActionText !== null ? closeActionText : "Close"}
                        placement="top"
                        disableFocusListener
                        disableTouchListener
                    >
                        <IconButton
                            color="inherit"
                            aria-label={closeActionText !== null ? closeActionText : "Close"}
                            onClick={() => {
                                closeAction();
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Tooltip>
                    <Typography style={{ color: this.props.theme.palette.primary.contrastText }}>
                        {text !== null ? text : lang.history.bar_calculation_selected}
                    </Typography>
                </div>
                <div className={"bottom-selection-action"}>
                    {typeof secondaryAction !== "undefined" ? (
                        <Button variant="contained" color="secondary" style={{ marginRight: 20 }} onClick={secondaryAction}>
                            {secondaryActionText !== null ? secondaryActionText : "unload"}
                        </Button>
                    ) : (
                        undefined
                    )}
                    {typeof primaryAction !== "undefined" ? (
                        <Button variant="contained" color="secondary" onClick={primaryAction}>
                            {primaryActionText !== null ? primaryActionText : "load"}
                        </Button>
                    ) : (
                        undefined
                    )}
                </div>
            </div>
        ) : (
            <Fragment />
        );
    }
}

export default connect(
    mapStateToProps,
    {}
)(withTheme()(BottomSelectionLogBar));
