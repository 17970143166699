import { connect } from "react-redux";
import React, { Fragment, useEffect } from "react";

import { attachResizeListener } from "../../Actions/cssActions/actions";
import { getLanguage } from "../../Actions/languageAction/actions";
import { createLog, getLastLog } from "../../Actions/logActions/actions";
import { mapStateToProps } from "../../redux";

function BootApplication(props) {
    useEffect(() => {
        const { getLanguage, auth, getLastLog, calculator, attachResizeListener } = props;
        const { functions } = calculator;

        getLanguage(auth.token);

        let newProduct = {};

        getLastLog(auth)
            .then(log => {
                return functions.loadProductFromLog(log);
            })
            .then(newLog => {
                return functions.requestProduct(newLog === null ? null : newLog.product.code);
            })
            .then(newProductProps => {
                newProduct = newProductProps;
                return functions.requestCalculation();
            })
            .then(() => {
                return functions.requestMaxMortgageFromIncomeCalculation();
            })
            .then(newMaxMortgageIncome => {
                props.createLog(auth, {
                    ...calculator,
                    product: newProduct,
                    maxMortgageAmountIncome: newMaxMortgageIncome
                });
            });

        attachResizeListener();
    }, []);

    const { children } = props;

    return <Fragment>{children}</Fragment>;
}

export default connect(
    mapStateToProps,
    { createLog, getLanguage, attachResizeListener, getLastLog }
)(BootApplication);
