export const mortgageRateFragment = `
fragment mortgageRateFragment on MortgageLogProductRates {
    type
    period
    nhg90
    nhg
    ltv_0_40
    ltv_0_45
    ltv_0_50
    ltv_0_54
    ltv_0_55
    ltv_0_60
    ltv_0_61
    ltv_0_65
    ltv_0_66
    ltv_0_675
    ltv_0_70
    ltv_0_71
    ltv_0_75
    ltv_0_80
    ltv_0_81
    ltv_0_85
    ltv_0_88
    ltv_0_90
    ltv_0_91
    ltv_0_95
    ltv_1_00
    ltv_1_01
    ltv_1_02
    ltv_1_03
    ltv_1_04
    ltv_1_05
    ltv_1_06
    ltv_1_08
    ltv_1_10
    ltv_1_125
    ltv_1_15
    ltv_1_20
}`;
