import { SET_LANGUAGE, GET_LANGUAGE } from "../Actions/languageAction/types";
import english from "../Languages/English.json";
import dutch from "../Languages/Dutch.json";
import { log } from "../redux";

const initialState = {
    //selected language of the website
    language: localStorage.getItem("lang") === "Dutch" ? dutch : english
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_LANGUAGE:
            log(SET_LANGUAGE, action);
            return {
                ...state,
                language: action.payload
            };
        case GET_LANGUAGE:
            log(GET_LANGUAGE, action);
            return {
                ...state,
                language: action.payload
            };
        default:
            return state;
    }
}
