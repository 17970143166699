import React from "react";
import { Card, Typography, CardContent, TextField, Button, FormControl, FormHelperText, CircularProgress } from "@material-ui/core";
import "./ResetPasswordStyles.css";
import axios from "axios";

import { api } from "../../services";
import logo from "../../Images/logo_hyponav_beta.svg";

export default class SendEmailResetPassword extends React.Component {
    state = {
        password: "",
        confirmPassword: "",
        errors: {
            password: false,
            confirmPassword: false
        },
        isLoading: false
    };

    extractToken = token => {
        return token.replace("?token=", "");
    };

    onInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    resetPassword = () => {
        const { password, confirmPassword, errors } = this.state;
        this.setState({ isLoading: true });

        if (confirmPassword !== password) {
            this.setState({ errors: { ...errors, confirmPassword: true }, isLoading: false });
            return;
        } else {
            this.setState({ errors: { ...errors, confirmPassword: false } });
        }

        axios({
            method: "post",
            url: `${api.url}/auth/reset`,
            data: {
                reset_password_token: this.extractToken(this.props.location.search),
                password: password,
                confirm_password: confirmPassword
            }
        })
            .then(response => {
                if (response.data.data.code === "password-reset/successful") {
                    this.props.history.push("/login");
                }
            })
            .catch(error => {
                if (error.response.data.error.code.includes("password")) {
                    this.setState({ errors: { ...errors, password: true }, isLoading: false });
                } else {
                    console.log("A new token is needed");
                }
            });
    };

    redirectToLogin = () => {
        this.props.history.push("/login");
    };

    render() {
        const { errors, isLoading } = this.state;

        return (
            <div className={"dom"}>
                <img src={logo} alt="Logo" style={{ width: "300px", margin: "40px 0" }} />
                <Card className={"card"}>
                    <CardContent className={"card-content"}>
                        <Typography variant={"h2"} style={{ margin: "20px 0" }}>
                            Reset
                        </Typography>
                        <FormControl error={errors.password} className={"sign-form-input"}>
                            <TextField
                                error={errors.password}
                                label="Password"
                                className={"sign-input"}
                                variant={"outlined"}
                                type="password"
                                name="password"
                                onChange={this.onInputChange}
                                margin="normal"
                            />
                            {errors.password ? (
                                <FormHelperText className={"error-message"}>
                                    The password must contain only letters and numbers and must be 6 characters long or more.
                                </FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>

                        <FormControl error={errors.confirmPassword} className={"sign-form-input"}>
                            <TextField
                                error={errors.confirmPassword}
                                label="Confirm Password"
                                className={"sign-input"}
                                variant={"outlined"}
                                type="password"
                                name="confirmPassword"
                                onChange={this.onInputChange}
                                margin="normal"
                            />
                            {errors.confirmPassword ? (
                                <FormHelperText className={"error-message"}>The passwords do not match.</FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>

                        <Button
                            size="large"
                            color="secondary"
                            variant="contained"
                            className={"sign-in-button"}
                            onClick={this.resetPassword}
                        >
                            {isLoading ? <CircularProgress className={"loader"} /> : "Reset"}
                        </Button>
                        <div className={"sign-help"}>
                            <Button variant="text" color="primary" onClick={this.redirectToLogin}>
                                Remembered your password?
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }
}
