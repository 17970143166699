import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Collapse, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import axios from "axios";
import { CalculatorContext } from "../../../Contexts/CalculatorContext";
import Radio from "../../Inputs/RadioField/RadioField";
import Input from "../../Inputs/InputField/InputField";
import { api } from "../../../services";
import { mapStateToProps } from "../../../redux";
import { createLog } from "../../../Actions/logActions/actions";

class ApplicantsDialog extends Component {
    static contextType = CalculatorContext;

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            calculateIncomeForIndex: 0,
            isSecondaryDialogOpen: false,
            employmentType: "",
            employedEmploymentType: "",
            letterOfIntent: "",
            salaryPeriodType: "",
            salaryAmount: "",
            salaryAmountProps: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            salaryHolidayAllowanceType: "",
            salaryHolidayAmount: "",
            salaryHolidayAmountProps: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            salaryHolidayPercentage: "",
            salaryHolidayPercentageProps: {
                error: null,
                conditions: { min: 0, max: 100 },
                errors: { min: "min", max: "max" }
            },
            incomeYear1: 0,
            incomeYear2: 0,
            incomeYear3: 0,
            incomeYear1Props: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            incomeYear2Props: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            incomeYear3Props: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },

            salaryThirteenthMonth: "",
            salaryThirteenthMonthProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryIrregularitySupplement: "",
            salaryIrregularitySupplementProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryCommission: "",
            salaryCommissionProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryEndOfYearBonus: "",
            salaryEndOfYearBonusProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryOvertime: "",
            salaryOvertimeProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryOther: "",
            salaryOtherProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            studyLoanValidationResult: null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open === true && prevProps.open !== this.props.open) {
            this.context.state.checkPoint().then();
        }
    }

    handleMainAction = () => {
        const { onClose, createLog, auth } = this.props;
        const { functions } = this.context;
        //some action

        functions.requestMaxMortgageFromIncomeCalculation().then(newMaxMortgageIncome => {
            createLog(auth, { ...this.context, maxMortgageAmountIncome: newMaxMortgageIncome });

            onClose();
        });
    };

    handleCancelAction = () => {
        const { onClose } = this.props;

        this.context.state.rollback().then(() => {
            onClose();
        });
    };

    openSecondaryDialog = index => {
        this.setState({ isSecondaryDialogOpen: true, calculateIncomeForIndex: index });
    };

    closeSecondaryDialog = () => {
        this.setState({ isSecondaryDialogOpen: false }, () => {
            this.refreshDialogValues();
        });
    };

    onChangeHandler = (name, event) => {
        this.setState({ [name]: event.target.value }, () => {});
    };

    onChangeHandlerTextInput = (name, event) => {
        const inputProps = this.state[`${name}Props`];

        const validation = this.validate(inputProps, event.target.value.split(".").join(""));

        this.setState({ [name]: event.target.value.split(".").join(""), [`${name}Props`]: { ...inputProps, error: validation } }, () => {});
    };

    validate = (props, value) => {
        const formatedValue = value !== "" ? parseInt(value) : value;

        if (!("conditions" in props) || formatedValue === props.conditions.equals) {
            return null;
        }
        if (formatedValue < props.conditions.min) {
            return "min";
        }
        if (formatedValue > props.conditions.max) {
            return "max";
        }
        return null;
    };

    thousandSeparator = number => {
        const parts = number.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
    };

    handleMainActionSecondModal = () => {
        const formData = new FormData();

        formData.append("employment_type", this.state.employmentType);
        if (this.state.employedEmploymentType !== "") {
            formData.append("employed_employment_type", this.state.employedEmploymentType);
        }
        if (this.state.letterOfIntent !== "") {
            formData.append("letter_of_intent", this.state.letterOfIntent);
        }
        if (this.state.salaryPeriodType !== "") {
            formData.append("salary_period_type", this.state.salaryPeriodType);
        }
        if (this.state.salaryAmount !== "") {
            formData.append("salary", this.state.salaryAmount);
        }
        if (this.state.incomeYear1 !== "") {
            formData.append("income_year_1", this.state.incomeYear1);
        }
        if (this.state.incomeYear2 !== "") {
            formData.append("income_year_2", this.state.incomeYear2);
        }
        if (this.state.incomeYear3 !== "") {
            formData.append("income_year_3", this.state.incomeYear3);
        }
        if (this.state.salaryHolidayAllowanceType !== "") {
            formData.append("salary_allowance_type", this.state.salaryHolidayAllowanceType);
        }
        if (this.state.salaryHolidayAmount !== "") {
            formData.append("salary_holiday_amount", this.state.salaryHolidayAmount);
        }
        if (this.state.salaryHolidayPercentage !== "") {
            formData.append("salary_holiday_percentage", this.state.salaryHolidayPercentage / 100);
        }
        if (this.state.salaryThirteenthMonth !== "") {
            formData.append("salary_thirteenth_month", this.state.salaryThirteenthMonth);
        }
        if (this.state.salaryIrregularitySupplement !== "") {
            formData.append("salary_irregularity_supplement", this.state.salaryIrregularitySupplement);
        }
        if (this.state.salaryCommission !== "") {
            formData.append("salary_commission", this.state.salaryCommission);
        }
        if (this.state.salaryEndOfYearBonus !== "") {
            formData.append("salary_end_of_year_bonus", this.state.salaryEndOfYearBonus);
        }
        if (this.state.salaryOvertime !== "") {
            formData.append("salary_overtime", this.state.salaryOvertime);
        }
        if (this.state.salaryOther !== "") {
            formData.append("salary_other", this.state.salaryOther);
        }
        axios({
            method: "post",
            url: `${api.url}/income`,
            data: formData,
            headers: { Authorization: `Bearer ${this.props.auth.token}` }
        })
            .then(response => {
                const data = response.data.data;

                this.context.applicants.setApplicantAmount(this.state.calculateIncomeForIndex, Math.round(data), () => {
                    this.closeSecondaryDialog();
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    refreshDialogValues = () => {
        this.setState({
            calculateIncomeForIndex: 0,
            isSecondaryDialogOpen: false,
            employmentType: "",
            employedEmploymentType: "",
            letterOfIntent: "",
            salaryPeriodType: "",
            salaryAmount: "",
            salaryAmountProps: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            salaryHolidayAllowanceType: "",
            salaryHolidayAmount: "",
            salaryHolidayAmountProps: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            salaryHolidayPercentage: "",
            salaryHolidayPercentageProps: {
                error: null,
                conditions: { min: 0, max: 100 },
                errors: { min: "min", max: "max" }
            },
            incomeYear1: 0,
            incomeYear2: 0,
            incomeYear3: 0,
            incomeYear1Props: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            incomeYear2Props: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },
            incomeYear3Props: {
                error: null,
                conditions: { min: 5000, max: 1000000 },
                errors: { min: "min", max: "max" }
            },

            salaryThirteenthMonth: "",
            salaryThirteenthMonthProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryIrregularitySupplement: "",
            salaryIrregularitySupplementProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryCommission: "",
            salaryCommissionProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryEndOfYearBonus: "",
            salaryEndOfYearBonusProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryOvertime: "",
            salaryOvertimeProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            },
            salaryOther: "",
            salaryOtherProps: {
                error: null,
                conditions: { min: 0, max: 10000 },
                errors: { min: "min", max: "max" }
            }
        });
    };

    render() {
        const calculatorContext = this.context;
        const { open, lang } = this.props;

        return (
            <Fragment>
                <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
                    <DialogTitle>{lang.income.modal_title}</DialogTitle>
                    <DialogContent className={"dialog-content"}>
                        <div className={"content-wrapper"}>
                            <div className={"content-side"}>
                                <Radio
                                    onChange={event => {
                                        if (event.target.value === "1") {
                                            calculatorContext.applicants.removeApplicant();
                                        } else {
                                            calculatorContext.applicants.addApplicant();
                                        }
                                    }}
                                    inputs={calculatorContext.applicants.inputs}
                                    value={calculatorContext.applicants.incomes.length}
                                    label={lang.income.number_applicants_label}
                                    name={"number-of-applicants"}
                                    tooltipText={lang.income.number_applicants_help}
                                />
                                {calculatorContext.applicants.incomes.map((income, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <Input
                                                id={`Income applicant ${index + 1}`}
                                                label={
                                                    calculatorContext.applicants.incomes.length === 1
                                                        ? lang.income.income_applicant_label
                                                        : `${lang.income.income_applicant_label} ${index + 1}`
                                                }
                                                value={this.thousandSeparator(income.value)}
                                                onChange={event => {
                                                    calculatorContext.applicants.setApplicantAmount(
                                                        index,
                                                        event.target.value.split(".").join("")
                                                    );
                                                }}
                                                error={income.error}
                                                tooltipText={lang.income.income_applicant_help}
                                                errors={
                                                    index === 0
                                                        ? {
                                                              min: `${this.props.lang.mortgageAmount.min_price} 5.000`,
                                                              max: `${this.props.lang.mortgageAmount.max_price}  1.000.000`
                                                          }
                                                        : {
                                                              min: `${this.props.lang.mortgageAmount.min_price} 5.000 or 0`,
                                                              max: `${this.props.lang.mortgageAmount.max_price}  1.000.000`
                                                          }
                                                }
                                            />
                                            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                                                <Button
                                                    color={"secondary"}
                                                    variant={"text"}
                                                    onClick={this.openSecondaryDialog.bind(this, index)}
                                                >
                                                    {lang.income.calculate_income_button}
                                                </Button>
                                            </div>
                                        </Fragment>
                                    );
                                })}
                                <Radio
                                    onChange={event => {
                                        calculatorContext.hasCredit.setValue(event.target.value === "true");
                                    }}
                                    inputs={calculatorContext.hasCredit.inputs}
                                    value={calculatorContext.hasCredit.value}
                                    label={lang.income.credit_label}
                                    name={"has-credit"}
                                    tooltipText={lang.income.credit_help}
                                />
                                <Collapse in={calculatorContext.hasCredit.value} timeout="auto" unmountOnExit>
                                    <Input
                                        id={"CreditLimit"}
                                        label={lang.income.credit_limit_label}
                                        value={this.thousandSeparator(calculatorContext.credit.value)}
                                        onChange={event => {
                                            calculatorContext.credit.setValue(event.target.value.split(".").join(""));
                                        }}
                                        error={calculatorContext.credit.error}
                                        errors={calculatorContext.credit.errors}
                                        tooltipText={lang.income.credit_limit_help}
                                    />
                                </Collapse>

                                <Radio
                                    onChange={event => {
                                        calculatorContext.hasStudyLoan.setValue(event.target.value === "true");
                                    }}
                                    inputs={calculatorContext.hasStudyLoan.inputs}
                                    value={calculatorContext.hasStudyLoan.value}
                                    label={lang.income.study_loan_label}
                                    name={"has-study-loan"}
                                    tooltipText={lang.income.study_loan_help}
                                />
                                <Collapse in={calculatorContext.hasStudyLoan.value} timeout="auto" unmountOnExit>
                                    <Input
                                        id={"StudyLoanStartAmount"}
                                        label={lang.income.study_loan_start_amount_label}
                                        value={this.thousandSeparator(calculatorContext.studyLoan.value)}
                                        onChange={event => {
                                            calculatorContext.studyLoan.setValue(event.target.value.split(".").join(""));
                                        }}
                                        error={calculatorContext.studyLoan.error}
                                        errors={calculatorContext.studyLoan.errors}
                                        tooltipText={lang.income.study_loan_start_amount_help}
                                    />
                                    <Input
                                        id={"StudyLoanStartDate"}
                                        label={lang.income.study_loan_start_date_label}
                                        type={"text"}
                                        placeholder={"dd-mm-yyyy"}
                                        tooltipText={lang.income.study_loan_start_date_help}
                                        onBlur={() => {
                                            const result = this.context.studyLoan.validateDate();

                                            if (result.error === null) {
                                                calculatorContext.studyLoan.setDate(result.date);
                                                this.setState({ studyLoanValidationResult: null });
                                            } else {
                                                this.setState({ studyLoanValidationResult: "invalid" });
                                            }
                                        }}
                                        value={calculatorContext.studyLoan.date}
                                        onChange={event => {
                                            calculatorContext.studyLoan.setDate(event.target.value);
                                        }}
                                        error={this.state.studyLoanValidationResult}
                                        errors={{ invalid: "Invalid date(format: dd-mm-yyyy)" }}
                                    />
                                </Collapse>
                            </div>
                            <div className={"content-side"}>
                                <Typography>{lang.income.income_explanation_par_1}</Typography>
                                <Typography>{lang.income.income_explanation_par_2}</Typography>
                                <Typography>{lang.income.income_explanation_par_3}</Typography>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancelAction} color="secondary">
                            {lang.income.button_cancel}
                        </Button>
                        <Button onClick={this.handleMainAction} color="secondary" variant={"contained"}>
                            {lang.income.button_apply}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog fullWidth={true} maxWidth={"md"} open={this.state.isSecondaryDialogOpen}>
                    <DialogTitle>{lang.incomeCalculation.modal_title}</DialogTitle>
                    <DialogContent className={"dialog-content"}>
                        <div className={"content-wrapper"}>
                            <div className={"content-side"}>
                                <Radio
                                    onChange={event => {
                                        this.onChangeHandler("employmentType", event);
                                    }}
                                    inputs={[
                                        { value: "employed", label: lang.incomeCalculation.work_type_employed },
                                        { value: "self_employed", label: lang.incomeCalculation.work_type_self_employed }
                                    ]}
                                    value={this.state.employmentType}
                                    label={lang.incomeCalculation.work_type_label}
                                    name={"employment-type"}
                                    tooltipText={lang.incomeCalculation.work_type_help}
                                />
                                <Collapse
                                    in={this.state.employmentType === "employed"}
                                    timeout="auto"
                                    unmountOnExit
                                    onExit={() => {
                                        this.setState({ employedEmploymentType: "" });
                                    }}
                                >
                                    <Radio
                                        onChange={event => {
                                            this.onChangeHandler("employedEmploymentType", event);
                                        }}
                                        inputs={[
                                            { value: "permanent", label: lang.incomeCalculation.employment_type_permanent },
                                            { value: "temporary", label: lang.incomeCalculation.employment_type_temporary },
                                            { value: "flexible", label: lang.incomeCalculation.employment_type_flexible }
                                        ]}
                                        value={this.state.employedEmploymentType}
                                        label={lang.incomeCalculation.employment_type_label}
                                        name={"employed-employment-type"}
                                        tooltipText={lang.incomeCalculation.employment_type_help}
                                    />
                                </Collapse>
                                <Collapse
                                    in={this.state.employedEmploymentType === "temporary"}
                                    timeout="auto"
                                    unmountOnExit
                                    onExit={() => {
                                        this.setState({ letterOfIntent: "" });
                                    }}
                                >
                                    <Radio
                                        onChange={event => {
                                            this.onChangeHandler("letterOfIntent", event);
                                        }}
                                        inputs={[
                                            { value: "yes", label: lang.incomeCalculation.letter_of_intent_yes },
                                            { value: "no", label: lang.incomeCalculation.letter_of_intent_no }
                                        ]}
                                        value={this.state.letterOfIntent}
                                        label={lang.incomeCalculation.letter_of_intent_label}
                                        name={"letter-of-intent"}
                                        tooltipText={lang.incomeCalculation.letter_of_intent_help}
                                    />
                                </Collapse>
                                <Collapse
                                    in={
                                        this.state.employmentType === "self_employed" ||
                                        this.state.employedEmploymentType === "flexible" ||
                                        this.state.letterOfIntent === "no"
                                    }
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    {[0, 1, 2].map(index => {
                                        return (
                                            <Input
                                                key={index}
                                                id={`income-year-${index + 1}`}
                                                label={`${lang.incomeCalculation.income_year_label} ${index + 1}`}
                                                value={this.thousandSeparator(this.state[`incomeYear${index + 1}`])}
                                                onChange={event => {
                                                    this.onChangeHandlerTextInput(`incomeYear${index + 1}`, event);
                                                }}
                                                error={this.state[`incomeYear${index + 1}Props`].error}
                                                errors={this.state[`incomeYear${index + 1}Props`].errors}
                                            />
                                        );
                                    })}
                                </Collapse>
                                <Collapse
                                    in={this.state.employedEmploymentType === "permanent" || this.state.letterOfIntent === "yes"}
                                    timeout="auto"
                                    unmountOnExit
                                    onExit={() => {
                                        this.setState({ salaryPeriodType: "", salaryHolidayAllowanceType: "" });
                                    }}
                                >
                                    <Radio
                                        onChange={event => {
                                            this.onChangeHandler("salaryPeriodType", event);
                                        }}
                                        inputs={[
                                            { value: "yearly", label: lang.incomeCalculation.salary_period_year },
                                            { value: "monthly", label: lang.incomeCalculation.salary_period_month },
                                            { value: "weekly", label: lang.incomeCalculation.salary_period_4weeks }
                                        ]}
                                        value={this.state.salaryPeriodType}
                                        label={lang.incomeCalculation.salary_period_label}
                                        name={"salary-period-type"}
                                        tooltipText={lang.incomeCalculation.salary_period_help}
                                    />
                                    <Collapse
                                        in={this.state.salaryPeriodType !== ""}
                                        timeout="auto"
                                        unmountOnExit
                                        onExit={() => {
                                            this.setState({ salaryAmount: "" });
                                        }}
                                    >
                                        <Input
                                            id={"salary-amount"}
                                            label={"Salary amount"}
                                            value={this.thousandSeparator(this.state.salaryAmount)}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryAmount", event);
                                            }}
                                            error={this.state.salaryAmountProps.error}
                                            errors={this.state.salaryAmountProps.errors}
                                        />
                                        <Input
                                            id={"salaryThirteenthMonth"}
                                            label={"Salary thirteenth month"}
                                            value={this.thousandSeparator(this.state.salaryThirteenthMonth)}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryThirteenthMonth", event);
                                            }}
                                            error={this.state.salaryThirteenthMonthProps.error}
                                            errors={this.state.salaryThirteenthMonthProps.errors}
                                        />
                                        <Input
                                            id={"salaryIrregularitySupplement"}
                                            label={"Salary irregularity supplement"}
                                            value={this.thousandSeparator(this.state.salaryIrregularitySupplement)}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryIrregularitySupplement", event);
                                            }}
                                            error={this.state.salaryIrregularitySupplementProps.error}
                                            errors={this.state.salaryIrregularitySupplementProps.errors}
                                        />
                                        <Input
                                            id={"salaryCommission"}
                                            label={"Salary commission"}
                                            value={this.thousandSeparator(this.state.salaryCommission)}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryCommission", event);
                                            }}
                                            error={this.state.salaryCommissionProps.error}
                                            errors={this.state.salaryCommissionProps.errors}
                                        />
                                        <Input
                                            id={"salaryEndOfYearBonus"}
                                            label={"Salary end of year bonus"}
                                            value={this.thousandSeparator(this.state.salaryEndOfYearBonus)}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryEndOfYearBonus", event);
                                            }}
                                            error={this.state.salaryEndOfYearBonusProps.error}
                                            errors={this.state.salaryEndOfYearBonusProps.errors}
                                        />
                                        <Input
                                            id={"salaryOvertime"}
                                            label={"Salary overtime"}
                                            value={this.thousandSeparator(this.state.salaryOvertime)}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryOvertime", event);
                                            }}
                                            error={this.state.salaryOvertimeProps.error}
                                            errors={this.state.salaryOvertimeProps.errors}
                                        />
                                        <Input
                                            id={"salaryOther"}
                                            label={"Salary other"}
                                            value={this.thousandSeparator(this.state.salaryOther)}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryOther", event);
                                            }}
                                            error={this.state.salaryOtherProps.error}
                                            errors={this.state.salaryOtherProps.errors}
                                        />
                                    </Collapse>
                                    <Radio
                                        onChange={event => {
                                            this.onChangeHandler("salaryHolidayAllowanceType", event);
                                        }}
                                        inputs={[
                                            { value: "standard", label: "Standard" },
                                            { value: "amount", label: "Amount" },
                                            { value: "percentage", label: "Percentage" }
                                        ]}
                                        value={this.state.salaryHolidayAllowanceType}
                                        label={"Salary holiday allowance type"}
                                        name={"salary-holiday-allowance-type"}
                                    />
                                    <Collapse
                                        in={this.state.salaryHolidayAllowanceType === "amount"}
                                        timeout="auto"
                                        unmountOnExit
                                        onExit={() => {
                                            this.setState({ salaryHolidayAmount: "" });
                                        }}
                                    >
                                        <Input
                                            id={"salary-holiday-amount"}
                                            label={"Salary holiday amount"}
                                            value={this.thousandSeparator(this.state.salaryHolidayAmount)}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryHolidayAmount", event);
                                            }}
                                            error={this.state.salaryHolidayAmountProps.error}
                                            errors={this.state.salaryHolidayAmountProps.errors}
                                        />
                                    </Collapse>
                                    <Collapse
                                        in={this.state.salaryHolidayAllowanceType === "percentage"}
                                        timeout="auto"
                                        unmountOnExit
                                        onExit={() => {
                                            this.setState({ salaryHolidayPercentage: "" });
                                        }}
                                    >
                                        <Input
                                            id={"salary-percentage-amount"}
                                            label={"Salary percentage"}
                                            value={this.thousandSeparator(this.state.salaryHolidayPercentage)}
                                            onChange={event => {
                                                this.onChangeHandlerTextInput("salaryHolidayPercentage", event);
                                            }}
                                            error={this.state.salaryHolidayPercentageProps.error}
                                            errors={this.state.salaryHolidayPercentageProps.errors}
                                        />
                                    </Collapse>
                                </Collapse>
                            </div>
                            <div className={"content-side"}>
                                <Typography>Here you can calculate the income.</Typography>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeSecondaryDialog} color="secondary">
                            cancel
                        </Button>
                        <Button onClick={this.handleMainActionSecondModal} color="secondary" variant={"contained"}>
                            Calculate
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    { createLog }
)(withTheme()(ApplicantsDialog));
