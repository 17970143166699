import { MENU_CLOSE, MENU_OPEN, MENU_TOGGLE } from "./types";

/**
 * changes the isOpen value in the store
 * * The function returns a Promise
 * * * then: returns menu state <Boolean>
 * * * catch: return error info
 */
export const openMenu = () => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: MENU_OPEN,
                payload: true
            });

            resolve(true);
        } catch (error) {
            reject(error);

            console.error("MENU_OPEN::FAIL", error);
        }
    });
};

/**
 * changes the isOpen value in the store
 * * The function returns a Promise
 * * * then: returns menu state <Boolean>
 * * * catch: return error info
 */
export const closeMenu = () => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: MENU_CLOSE,
                payload: false
            });

            resolve(false);
        } catch (error) {
            reject(error);

            console.error("MENU_CLOSE::FAIL", error);
        }
    });
};

/**
 * changes the isOpen value in the store
 * * The function returns a Promise
 * * * then: void
 * * * catch: return error info
 */
export const toggleMenu = () => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: MENU_TOGGLE,
                payload: null
            });

            resolve();
        } catch (error) {
            reject(error);

            console.error("MENU_TOGGLE::FAIL", error);
        }
    });
};
