import { IS_MOBILE } from "../Actions/cssActions/types";
import { log } from "../redux";

const initialState = {
    isMobile: document.body.clientWidth <= 767
};

export default function(state = initialState, action) {
    switch (action.type) {
        case IS_MOBILE:
            log(IS_MOBILE, action);
            return {
                ...state,
                isMobile: action.payload
            };
        default:
            return state;
    }
}
