import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";

import { createLog } from "../../../Actions/logActions/actions";
import { mapStateToProps } from "../../../redux";

import Radio from "../../Inputs/RadioField/RadioField";

import { CalculatorContext } from "../../../Contexts/CalculatorContext";

function MortgageTypeDialog(props) {
    const context = useContext(CalculatorContext);

    useEffect(() => {
        if (props.open === true) {
            context.state.checkPoint().then();
        }
    }, [props.open]);

    const handleMainAction = () => {
        const { onClose, createLog, auth } = props;
        const { functions, product } = context;

        
        functions.requestProduct(product.code).then(newProduct => {
            functions.requestCalculation().then(() => {
                functions.requestMaxMortgageFromIncomeCalculation().then(newMaxMortgageIncome => {
                    createLog(auth, { ...context, product: newProduct, maxMortgageAmountIncome: newMaxMortgageIncome });
                });

                onClose();
            });
        });
    };

    const handleCancelAction = () => {
        const { onClose } = props;

        context.state.rollback().then(() => {
            onClose();
        });
    };

    //no need for defining available options as if the product has fixed type as an option
    //when renderitng and callcing this function for "fixed" it will return null and
    //the option will be filtered out(not rendered)
    const translateType = type => {
        switch (type) {
            case "annuity":
                return lang.dashboard.input_mortgage_type_part_value_annuity;
            case "linear":
                return lang.dashboard.input_mortgage_type_part_value_linear;
            default:
                return null;
        }
    };

    const { parts, product } = context;
    const { open, lang } = props;

    return (
        <Dialog fullWidth={true} maxWidth={"lg"} open={open}>
            <DialogTitle>{lang.type.modal_title}</DialogTitle>
            <DialogContent className={"dialog-content"}>
                <div className={"content-wrapper"}>
                    <div className={"content-side"}>
                        {parts.parts.map((part, key) => {
                            return (
                                <Radio
                                    key={key}
                                    onChange={event => {
                                        parts.setPartMortgageType(event.target.value, key);
                                    }}
                                    column={true}
                                    inputs={product.available_types
                                        .map(option => {
                                            return { label: translateType(option), value: option };
                                        })
                                        .filter(value => {
                                            return value.label !== null;
                                        })}
                                    value={part.mortgageType.value}
                                    label={
                                        parts.parts.length > 1
                                            ? `${lang.type.mortgage_type_part_label} ${key + 1}`
                                            : lang.type.mortgage_type_label
                                    }
                                    tooltipText={lang.type.mortgage_type_help}
                                    name={`mortgage-type-part-${key}`}
                                />
                            );
                        })}
                    </div>
                    <div className={"content-side"}>
                        <Typography>{lang.type.mortgage_type_explanation_par_1}</Typography>
                        <Typography>{lang.type.mortgage_type_explanation_par_2}</Typography>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelAction} color="secondary">
                    {lang.type.button_cancel}
                </Button>
                <Button onClick={handleMainAction} color="secondary" variant={"contained"}>
                    {lang.type.button_apply}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

MortgageTypeDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default connect(
    mapStateToProps,
    { createLog }
)(withTheme()(MortgageTypeDialog));
