import {
    AUTH_VERIFY_EMAIL,
    AUTH_SIGN_OUT,
    AUTH_SIGN_IN_DEFAULT,
    AUTH_SIGN_UP_DEFAULT,
    AUTH_VERIFY_TOKEN,
    AUTH_CHANGE_ROLE
} from "../Actions/authActions/types";
import { log } from "../redux";

const initialState = {
    user: null,
    token: null,
    isAuthStateChanged: false,
    isUserAuthenticated: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case AUTH_VERIFY_EMAIL:
            log(AUTH_VERIFY_EMAIL, action);
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,
                isUserAuthenticated: action.payload !== null
            };
        case AUTH_VERIFY_TOKEN:
            log(AUTH_VERIFY_TOKEN, action);
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,
                isAuthStateChanged: true,
                isUserAuthenticated: action.payload !== null
            };
        case AUTH_SIGN_UP_DEFAULT:
            log(AUTH_SIGN_UP_DEFAULT, action);
            return {
                ...state,
                user: action.payload
            };
        case AUTH_SIGN_OUT:
            log(AUTH_SIGN_OUT, action);
            return {
                ...state,
                user: action.payload,
                isUserAuthenticated: false
            };
        case AUTH_SIGN_IN_DEFAULT:
            log(AUTH_SIGN_IN_DEFAULT, action);
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,
                isUserAuthenticated: action.payload !== null
            };
        case AUTH_CHANGE_ROLE:
            log(AUTH_CHANGE_ROLE, action);
            return {
                ...state,
                token: action.payload.token,
                user: { ...state.user, role: action.payload.role }
            };
        default:
            return state;
    }
}
