import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import React, { Fragment, useEffect } from "react";

import { mapStateToProps } from "./../redux";
import { authVerifyToken } from "../Actions/authActions/actions";

//Contexts
import { CalculatorContextComponent, CalculatorContext } from "./../Contexts/CalculatorContext";

//Boot
import BootApplication from "../Components/BootApplication/BootApplication";

//Views
import Advisor from "../Views/Advisor/Advisor";
import Calculator from "../Views/Calculator/Calculator";
import EmailSend from "../Views/EmailSend/EmailSend";
import History from "../Views/History/History";
import Home from "../Views/Home/Home";
import NotFound from "../Views/404/404";
import ResetPassword from "../Views/ResetPassword/ResetPassword";
import SendEmailResetPassword from "../Views/ResetPassword/SendEmailResetPassword";
import SignIn from "../Views/SignIn/SignIn";
import SignUp from "../Views/SignUp/SignUp";
import VerifyEmail from "../Views/VerifyEmail/VerifyEmail";

function Router(props) {
    useEffect(() => {
        props.authVerifyToken();
    }, []);

    const { auth, lang } = props;

    return !auth.isAuthStateChanged ? (
        <div />
    ) : (
        <BrowserRouter>
            <Switch>
                {auth.isUserAuthenticated && auth.token !== null ? (
                    <CalculatorContextComponent auth={auth} lang={lang}>
                        <CalculatorContext.Consumer>
                            {calculatorContext => {
                                return (
                                    <BootApplication calculator={calculatorContext}>
                                        {calculatorContext.product !== null ? (
                                            <Switch>
                                                <Route path="/" exact component={Home} />
                                                <Route path="/dashboard" exact component={Calculator} />
                                                <Route path="/history" exact component={History} />
                                                <Route path="/advisor" exact component={Advisor} />
                                                <Redirect to={"/dashboard"} />
                                            </Switch>
                                        ) : (
                                            <Fragment />
                                        )}
                                    </BootApplication>
                                );
                            }}
                        </CalculatorContext.Consumer>
                    </CalculatorContextComponent>
                ) : (
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/login" exact component={SignIn} />
                        <Route path="/register" exact component={SignUp} />
                        <Route path="/forgot" exact component={SendEmailResetPassword} />
                        <Route path="/reset" exact component={ResetPassword} />
                        <Route path="/send" exact component={EmailSend} />
                        <Route path="/verify" exact component={VerifyEmail} />
                        <Redirect to={"/login"} />
                    </Switch>
                )}
                <Route path="/404" exact component={NotFound} />
                <Redirect to={"/404"} />
            </Switch>
        </BrowserRouter>
    );
}

export default connect(
    mapStateToProps,
    { authVerifyToken }
)(Router);
