import React, { Component } from "react";
import { FormControl, TextField, FormHelperText, CircularProgress, Button, Card, CardContent, Typography } from "@material-ui/core";
import "./SignInStyles.css";
import { connect } from "react-redux";
import { mapStateToProps } from "../../redux";
import { signInDefault } from "../../Actions/authActions/actions";
import logo from "../../Images/logo_hyponav_beta.svg";

class SignIn extends Component {
    state = {
        email: "",
        password: "",
        errors: {
            email: false,
            password: false
        },
        emailErrorMessage: "",
        isLoading: false
    };

    onInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    signIn = () => {
        const { email, password, errors } = this.state;

        this.setState({ isLoading: true });

        let _errors = errors;

        this.props.signInDefault({ email: email, password: password }).catch(error => {
            _errors.email = error.code.includes("email");

            _errors.password = error.code.includes("password");

            this.setState({
                errors: _errors,
                isLoading: false,
                emailErrorMessage: error.code === "email/not-verified" ? "Please verify your email" : "Please enter a valid email"
            });
        });
    };

    redirectToSignUp = () => {
        this.props.history.push("/register");
    };

    redirectToResetPassword = () => {
        this.props.history.push("/forgot");
    };

    render() {

        const { errors, isLoading, emailErrorMessage } = this.state;
        return (
            <div className={"dom"}>
                <img src={logo} alt="Logo" style={{ width: "300px", margin: "40px 0" }} />
                <Card className={"card"}>
                    <CardContent className={"card-content"}>
                        <Typography variant={"h2"} style={{ margin: "20px 0" }}>
                            Login
                        </Typography>
                        <FormControl error={errors.email} className={"sign-form-input"}>
                            <TextField
                                error={errors.email}
                                label="Email"
                                variant={"outlined"}
                                className={"sign-input"}
                                type="email"
                                name="email"
                                onChange={this.onInputChange}
                                autoComplete="email"
                                margin="normal"
                            />
                            {errors.email ? <FormHelperText className={"error-message"}>{emailErrorMessage}</FormHelperText> : undefined}
                        </FormControl>

                        <FormControl error={errors.password} className={"sign-form-input"}>
                            <TextField
                                error={errors.password}
                                label="Password"
                                className={"sign-input"}
                                variant={"outlined"}
                                type="password"
                                name="password"
                                onChange={this.onInputChange}
                                autoComplete="current-password"
                                margin="normal"
                            />
                            {errors.password ? (
                                <FormHelperText className={"error-message"}>
                                    The password is invalid or the user does not exists.
                                </FormHelperText>
                            ) : (
                                undefined
                            )}
                        </FormControl>

                        <Button size="large" color="secondary" variant="contained" className={"sign-in-button"} onClick={this.signIn}>
                            {isLoading ? <CircularProgress className={"loader"} /> : "Sign in"}
                        </Button>
                        <div className={"sign-help"}>
                            <Button color="primary" variant={"text"} onClick={this.redirectToResetPassword}>
                                Forgot password?
                            </Button>
                            <Button color="primary" variant={"text"} onClick={this.redirectToSignUp}>
                                Sign up
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    { signInDefault }
)(SignIn);
