import React, { Component, Fragment } from "react";
import { format } from "../../helperFunctions";
import { Card, CardContent } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import TextField from "../../Components/Inputs/TextField/TextField";
import ChartContainer from "../../Components/ChartContainer/ChartContainer";
import { SubdirectoryArrowRight } from "@material-ui/icons";
import MortgageDialog from "../../Components/Dialogs/MortgageDialog/MortgageDialog";
import ApplicantsDialog from "../../Components/Dialogs/ApplicantsDialog/ApplicantsDialog";
import StartDateDialog from "../../Components/Dialogs/StartDateDialog/StartDateDialog";
import PartsDialog from "../../Components/Dialogs/PartsDialog/PartsDialog";
import ProductDialog from "../../Components/Dialogs/ProductDialog/ProductDialog";
import MortgageTypeDialog from "../../Components/Dialogs/MortgageTypeDialog/MortgageTypeDialog";
import MortgageDurationDialog from "../../Components/Dialogs/MortgageDurationDialog/MortgageDurationDialog";
import MortgageInterestFixedPeriodDialog from "../../Components/Dialogs/MortgageInterestFixedPeriodDialog/MortgageInterestFixedPeriodDialog";
import ErrorDialog from "../../Components/Dialogs/ErrorDialog/ErrorDialog";
import Template from "../../Components/Template/Template";
import BottomSelectionLogBar from "./../../Components/BottomSelectionLogBar/BottomSelectionLogBar";
import CheckBlock from "./CheckBlock/CheckBlock";
import { CalculatorContext } from "../../Contexts/CalculatorContext";
import moment from "moment";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";
import { unloadLog } from "../../Actions/logActions/actions";
import "./CalculatorStyles.css";

class Calculator extends Component {
    static contextType = CalculatorContext;

    constructor(props) {
        super(props);

        this.productInput = React.createRef();

        this.mortgageInput = React.createRef();
        this.partsInput = React.createRef();
        this.applicantsInput = React.createRef();
        this.startDateInput = React.createRef();
        this.valueHouseInput = React.createRef();
        this.ltvInput = React.createRef();
        this.nhgInput = React.createRef();

        this.partOneMortgageTypeInput = React.createRef();
        this.partTwoMortgageTypeInput = React.createRef();

        this.partOneMortgageDurationInput = React.createRef();
        this.partTwoMortgageDurationInput = React.createRef();

        this.partOneMortgageFixedPeriodInput = React.createRef();
        this.partTwoMortgageFixedPeriodInput = React.createRef();

        this.partOneMortgageAmountInput = React.createRef();
        this.partTwoMortgageAmountInput = React.createRef();

        this.partOneIncomeInput = React.createRef();
        this.partTwoIncomeInput = React.createRef();

        this.partOneInterestRateInput = React.createRef();
        this.partTwoInterestRateInput = React.createRef();

        this.state = {
            mortgageDialogTriggerElement: "none",
            isProductDialogOpen: false,
            isRatesErrorDialogOpen: false,
            isMortgageDialogOpen: false,
            isApplicantsDialogOpen: false,
            isStartDateDialogOpen: false,
            isMortgageTypeDialogOpen: false,
            isMortgageDurationDialogOpen: false,
            isMortgageFixedPeriodDialogOpen: false,
            isNumberOfPartsAndMortgageAmountDialogOpen: false
        };
    }

    componentDidMount() {
        const { logs } = this.props;

        const loadedLog = logs.currentLog;

        if (loadedLog !== null) {
            this.context.functions.loadFromLog(loadedLog);
        }
    }

    openDialog = (dialogStateVariableName, callback) => {
        const { logs } = this.props;

        if (logs.currentLog === null) {
            this.setState({ [dialogStateVariableName]: true }, () => {
                if (typeof callback !== "undefined") {
                    callback();
                }
            });
        }
    };

    closeDialog = (dialogStateVariableName, inputs) => {
        this.setState({ [dialogStateVariableName]: false }, () => {
            inputs.forEach(input => {
                if (input !== null) {
                    input.blur();
                }
            });
        });
    };

    handleSwitchChange = () => {
        this.setState(state => ({
            nhg: !state.nhg
        }));
    };

    removeLog = () => {
        this.context.state.rollback().then(() => {
            this.props.unloadLog();
        });
    };

    changeTypeLanguage = name => {
        const { lang } = this.props;
        let newName;
        name === "annuity"
            ? (newName = lang.dashboard.input_mortgage_type_part_value_annuity)
            : (newName = lang.dashboard.input_mortgage_type_part_value_linear);
        return newName;
    };

    thousandSeparator = number => {
        const parts = number.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
    };

    render() {
        const { logs, lang } = this.props;
        const calculatorContext = this.context;

        const {
            isProductDialogOpen,
            isMortgageDialogOpen,
            isNumberOfPartsAndMortgageAmountDialogOpen,
            isApplicantsDialogOpen,
            isStartDateDialogOpen,
            isMortgageTypeDialogOpen,
            isMortgageDurationDialogOpen,
            isMortgageFixedPeriodDialogOpen,
            mortgageDialogTriggerElement
        } = this.state;

        function getDescription() {
            if (calculatorContext.product.description === "Example product with the lowest rates") {
                return lang.dashboard.input_product_default_value;
            } else {
                return calculatorContext.product.description;
            }
        }

        return (
            <Fragment>
                <Template title={lang.dashboard.title_tag} shouldIncludeSideBar={true} history={this.props.history}>
                    <div style={{ padding: 10 }}>
                        <div className={"input-container"}>
                            <Card style={{ flexWrap: "wrap" }}>
                                <CardContent className={"input-block"}>
                                    <div className={"input-section left-side"}>
                                        <div className={"input-row"}>
                                            <div className={"input-wrapper full"}>
                                                <TextField
                                                    inputRef={this.productInput}
                                                    value={getDescription()}
                                                    label={"Product"}
                                                    type={"text"}
                                                    disabled={logs.currentLog !== null}
                                                    onClick={() => {
                                                        this.openDialog("isProductDialogOpen");
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={"input-row"}>
                                            <div className={"input-wrapper medium"}>
                                                <TextField
                                                    value={this.thousandSeparator(calculatorContext.mortgageAmount.value)}
                                                    label={lang.dashboard.input_mortgage_amount_label}
                                                    type={"text"}
                                                    inputRef={this.mortgageInput}
                                                    disabled={logs.currentLog !== null}
                                                    prefix={"€"}
                                                    onClick={() => {
                                                        this.openDialog("isMortgageDialogOpen", () => {
                                                            this.setState({ mortgageDialogTriggerElement: "none" });
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className={"input-wrapper small"}>
                                                <TextField
                                                    value={calculatorContext.parts.parts.length}
                                                    label={lang.dashboard.input_parts_label}
                                                    type={"text"}
                                                    inputRef={this.partsInput}
                                                    disabled={logs.currentLog !== null}
                                                    onClick={() => {
                                                        this.openDialog("isNumberOfPartsAndMortgageAmountDialogOpen");
                                                    }}
                                                />
                                            </div>
                                            <div className={"input-wrapper small"}>
                                                <TextField
                                                    value={
                                                        calculatorContext.isNhgPossible.value
                                                            ? !calculatorContext.forceNoNhg.value
                                                                ? lang.dashboard.input_nhg_value_yes
                                                                : lang.dashboard.input_nhg_value_no
                                                            : lang.dashboard.input_nhg_value_no
                                                    }
                                                    inputRef={this.nhgInput}
                                                    onClick={() => {
                                                        this.openDialog("isMortgageDialogOpen", () => {
                                                            this.setState({ mortgageDialogTriggerElement: "none" });
                                                        });
                                                    }}
                                                    disabled={logs.currentLog !== null}
                                                    label={lang.dashboard.input_nhg_label}
                                                    type={"text"}
                                                />
                                            </div>
                                            <div className={"input-wrapper medium"}>
                                                <TextField
                                                    value={calculatorContext.startDate.value}
                                                    label={lang.dashboard.input_start_date_label}
                                                    disabled={logs.currentLog !== null}
                                                    inputRef={this.startDateInput}
                                                    onClick={() => {
                                                        this.openDialog("isStartDateDialogOpen");
                                                    }}
                                                    type={"text"}
                                                />
                                            </div>
                                            <div className={"input-wrapper medium"}>
                                                <TextField
                                                    value={this.thousandSeparator(calculatorContext.valueHouse.value)}
                                                    label={lang.dashboard.input_value_house_label}
                                                    disabled={logs.currentLog !== null}
                                                    inputRef={this.valueHouseInput}
                                                    onClick={() => {
                                                        this.openDialog("isMortgageDialogOpen", () => {
                                                            this.setState({ mortgageDialogTriggerElement: "valueHouse" });
                                                        });
                                                    }}
                                                    type={"text"}
                                                    prefix={"€"}
                                                />
                                            </div>
                                            <div className={"input-wrapper medium"}>
                                                <TextField
                                                    value={`${calculatorContext.ltv.value}%`}
                                                    label={lang.dashboard.input_loan_to_value_label}
                                                    type={"text"}
                                                    disabled={logs.currentLog !== null}
                                                    inputRef={this.ltvInput}
                                                    onClick={() => {
                                                        this.openDialog("isMortgageDialogOpen", () => {
                                                            this.setState({ mortgageDialogTriggerElement: "none" });
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {calculatorContext.parts.parts.map((part, key) => {
                                            return (
                                                <div className={"input-row"} key={key}>
                                                    <div className={"part-arrow"}>
                                                        <SubdirectoryArrowRight />
                                                    </div>
                                                    <div className={"input-wrapper medium"}>
                                                        <TextField
                                                            value={this.thousandSeparator(part.mortgageAmount.value)}
                                                            label={`${lang.dashboard.input_mortgage_amount_part_label} ${key + 1}`}
                                                            disabled={logs.currentLog !== null}
                                                            onClick={() => {
                                                                this.openDialog("isNumberOfPartsAndMortgageAmountDialogOpen");
                                                            }}
                                                            inputRef={
                                                                key === 0
                                                                    ? this.partOneMortgageAmountInput
                                                                    : this.partTwoMortgageAmountInput
                                                            }
                                                            type={"text"}
                                                            prefix={"€"}
                                                        />
                                                    </div>
                                                    <div className={"input-wrapper medium"}>
                                                        <TextField
                                                            value={this.changeTypeLanguage(part.mortgageType.value)}
                                                            disabled={logs.currentLog !== null}
                                                            onClick={() => {
                                                                this.openDialog("isMortgageTypeDialogOpen");
                                                            }}
                                                            inputRef={
                                                                key === 0 ? this.partOneMortgageTypeInput : this.partTwoMortgageTypeInput
                                                            }
                                                            label={lang.dashboard.input_mortgage_type_part_label}
                                                            type={"text"}
                                                        />
                                                    </div>
                                                    <div className={"input-wrapper medium"}>
                                                        <TextField
                                                            value={part.duration.value}
                                                            label={lang.dashboard.input_mortgage_duration_part_label}
                                                            inputRef={
                                                                key === 0
                                                                    ? this.partOneMortgageDurationInput
                                                                    : this.partTwoMortgageDurationInput
                                                            }
                                                            disabled={logs.currentLog !== null}
                                                            onClick={() => {
                                                                this.openDialog("isMortgageDurationDialogOpen");
                                                            }}
                                                            type={"text"}
                                                        />
                                                    </div>
                                                    <div className={"input-wrapper medium"}>
                                                        <TextField
                                                            value={part.interestFixedPeriod.value}
                                                            inputRef={
                                                                key === 0
                                                                    ? this.partOneMortgageFixedPeriodInput
                                                                    : this.partTwoMortgageFixedPeriodInput
                                                            }
                                                            disabled={logs.currentLog !== null}
                                                            onClick={() => {
                                                                this.openDialog("isMortgageFixedPeriodDialogOpen");
                                                            }}
                                                            label={lang.dashboard.input_mortgage_interest_fixed_period_part_label}
                                                            type={"text"}
                                                        />
                                                    </div>
                                                    <div className={"input-wrapper medium"}>
                                                        <TextField
                                                            value={`${format(part.interestRate.value * 100, 2, 3, ".", ",")}%`}
                                                            inputRef={
                                                                key === 0 ? this.partOneInterestRateInput : this.partTwoInterestRateInput
                                                            }
                                                            onClick={() => {
                                                                this.openDialog("isProductDialogOpen");
                                                            }}
                                                            disabled={logs.currentLog !== null}
                                                            label={lang.dashboard.input_mortgage_interest_rate_part_label}
                                                            type={"text"}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={"input-section right-side"}>
                                        <div className={"input-row"}>
                                            <div className={"input-wrapper full"}>
                                                <TextField
                                                    value={calculatorContext.applicants.incomes.length}
                                                    label={lang.dashboard.input_applicants_number_label}
                                                    type={"text"}
                                                    disabled={logs.currentLog !== null}
                                                    inputRef={this.applicantsInput}
                                                    onClick={() => {
                                                        this.openDialog("isApplicantsDialogOpen");
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {calculatorContext.applicants.incomes.map((income, key) => {
                                            return (
                                                <div key={key} className={"input-row"}>
                                                    <div className={"input-wrapper full"}>
                                                        <TextField
                                                            disabled={logs.currentLog !== null}
                                                            value={this.thousandSeparator(income.value)}
                                                            onClick={() => {
                                                                this.openDialog("isApplicantsDialogOpen");
                                                            }}
                                                            inputRef={key === 0 ? this.partOneIncomeInput : this.partTwoIncomeInput}
                                                            label={`${lang.dashboard.input_applicants_income_person_label} ${key + 1}`}
                                                            type={"text"}
                                                            prefix={"€"}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </CardContent>
                            </Card>
                            <CheckBlock calculatorContext={calculatorContext} />
                        </div>
                        <ChartContainer
                            chartColor={"#448AFF"}
                            chartData={calculatorContext.calculations.netPaymentCalcualtions}
                            chartHeight={150}
                            chartTooltip={lang.dashboard.graph_net_payments_help}
                            chartTotalTitle={lang.dashboard.graph_net_payments_title}
                            chartTotalTooltip={lang.dashboard.total_net_help}
                            chartType={"BarChart"}
                            isChartDataLoading={calculatorContext.calculations.isLoading}
                            totals={[calculatorContext.calculations.totals.ifp.net, calculatorContext.calculations.totals.duration.net]}
                            totalsHeader={lang.dashboard.total_net_title}
                        />
                        <ChartContainer
                            chartData={calculatorContext.calculations.grossPaymentCalcualtions}
                            chartHeight={150}
                            chartTooltip={lang.dashboard.graph_gross_payments_help}
                            chartTotalTitle={lang.dashboard.graph_gross_payments_title}
                            chartTotalTooltip={lang.dashboard.total_gross_help}
                            chartType={"BarChart"}
                            isChartDataLoading={calculatorContext.calculations.isLoading}
                            totals={[calculatorContext.calculations.totals.ifp.gross, calculatorContext.calculations.totals.duration.gross]}
                            totalsHeader={lang.dashboard.total_gross_title}
                        />
                        <ChartContainer
                            chartData={calculatorContext.calculations.averageInterestRateCalcualtions}
                            chartHeight={150}
                            chartInfoDialog={true}
                            chartTooltip={lang.dashboard.graph_interest_rate_help}
                            chartTooltipType={"InterestRateTooltip"}
                            chartTotalTitle={lang.dashboard.graph_interest_rate_title}
                            chartTotalTooltip={lang.dashboard.average_interest_help}
                            chartType={"LineChart"}
                            isChartDataLoading={calculatorContext.calculations.isLoading}
                            isTotalValueWholeNumber={false}
                            totals={[
                                calculatorContext.calculations.totals.ifp.avgRate,
                                calculatorContext.calculations.totals.duration.avgRate
                            ]}
                            totalsHeader={lang.dashboard.average_interest_title}
                        />
                        <ChartContainer
                            chartData={calculatorContext.calculations.mortgageAmountCalcualtions}
                            chartTooltip={lang.dashboard.graph_mortgage_amount_help}
                            chartTotalTitle={lang.dashboard.graph_mortgage_amount_title}
                            chartType={"AreaChart"}
                            isChartDataLoading={calculatorContext.calculations.isLoading}
                        />
                    </div>
                    {logs.currentLog !== null ? <div style={{ marginTop: 50 }} /> : undefined}
                    <BottomSelectionLogBar
                        selectedLog={logs.currentLog === null ? null : logs.currentLog}
                        closeAction={this.removeLog}
                        text={
                            logs.currentLog === null
                                ? null
                                : `${lang.history.bar_calculation_loaded_title_view} ${moment(logs.currentLog.mils, "x").format(
                                      "DD-MM-YYYY, HH:mm"
                                  )} - ${lang.history.bar_calculation_loaded_title_change}`
                        }
                        primaryAction={this.removeLog}
                        primaryActionText={lang.history.bar_calculation_loaded_title_button_close}
                    />
                </Template>
                <MortgageDialog
                    open={isMortgageDialogOpen}
                    triggerElement={mortgageDialogTriggerElement}
                    onClose={this.closeDialog.bind(this, "isMortgageDialogOpen", [
                        this.mortgageInput.current,
                        this.valueHouseInput.current,
                        this.ltvInput.current,
                        this.nhgInput.current
                    ])}
                />
                <ProductDialog
                    open={isProductDialogOpen}
                    onClose={this.closeDialog.bind(this, "isProductDialogOpen", [
                        this.productInput.current,
                        this.partOneInterestRateInput.current,
                        this.partTwoInterestRateInput.current
                    ])}
                />
                <ApplicantsDialog
                    open={isApplicantsDialogOpen}
                    onClose={this.closeDialog.bind(this, "isApplicantsDialogOpen", [
                        this.applicantsInput.current,
                        this.partOneIncomeInput.current,
                        this.partTwoIncomeInput.current
                    ])}
                />
                <StartDateDialog
                    open={isStartDateDialogOpen}
                    onClose={this.closeDialog.bind(this, "isStartDateDialogOpen", [this.startDateInput.current])}
                />
                <MortgageTypeDialog
                    open={isMortgageTypeDialogOpen}
                    onClose={this.closeDialog.bind(this, "isMortgageTypeDialogOpen", [
                        this.partOneMortgageTypeInput.current,
                        this.partTwoMortgageTypeInput.current
                    ])}
                />
                <MortgageDurationDialog
                    open={isMortgageDurationDialogOpen}
                    onClose={this.closeDialog.bind(this, "isMortgageDurationDialogOpen", [
                        this.partOneMortgageDurationInput.current,
                        this.partTwoMortgageDurationInput.current
                    ])}
                />
                <MortgageInterestFixedPeriodDialog
                    open={isMortgageFixedPeriodDialogOpen}
                    onClose={this.closeDialog.bind(this, "isMortgageFixedPeriodDialogOpen", [
                        this.partOneMortgageFixedPeriodInput.current,
                        this.partTwoMortgageFixedPeriodInput.current
                    ])}
                />
                <ErrorDialog
                    open={calculatorContext.rateError}
                    onCancel={() => {
                        calculatorContext.state.rollback().then(() => {
                            calculatorContext.state.checkPoint().then();
                        });
                    }}
                    onApply={() => {
                        this.openDialog("isProductDialogOpen");
                    }}
                />
                <PartsDialog
                    open={isNumberOfPartsAndMortgageAmountDialogOpen}
                    onClose={this.closeDialog.bind(this, "isNumberOfPartsAndMortgageAmountDialogOpen", [
                        this.partsInput.current,
                        this.partOneMortgageAmountInput.current,
                        this.partTwoMortgageAmountInput.current
                    ])}
                />
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    { unloadLog }
)(withTheme()(Calculator));
