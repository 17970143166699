import { IS_MOBILE } from "./types";
import { debounce } from "lodash";

/**
 * Attaches resize event listener
 * @param {string} width
 */
export const attachResizeListener = () => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            window.addEventListener(
                "resize",
                debounce(function() {
                    dispatch({
                        type: IS_MOBILE,
                        payload: document.body.clientWidth <= 767
                    });
                }, 300),
                false
            );

            resolve();
        } catch (error) {
            reject(error);
            console.error("IS_MOBILE::FAIL", error);
        }
    });
};
