export const createMortgageLogMutation = `
mutation createMortgageLog($data: CreateMortgageLogInput!, $product: CreateMortgageLogProductInput!, $calculations: CreateMortgageLogCalculationsInput!){
    create_mortgage_log( data: $data, product: $product, calculations: $calculations){
        id
        input {
            mortgage_amount
            value_house
            purchase_price_house
            parts {
                mortgage_amount
                mortgage_type
                duration
                interest_fixed_period
                interest_rate
            }
            incomes
        }
        product {
            code
            description
            provider {
                name
                logo
            }
        }
        calculations{
            max_mortgage_from_income
        }
        created_at
    }
}`; 

export const softDeleteLogMutation = `
mutation softDeleteLog($id: Int!){
    paranoid_delete_mortgage_log(id: $id){
      id
      deleted_at
    }
}`;