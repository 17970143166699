import React, { Component, Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
    AppBar,
    Toolbar,
    Typography,
    Drawer,
    IconButton,
    ListItem,
    List,
    Divider,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Avatar,
    Menu,
    MenuItem,
    Button
} from "@material-ui/core";
import { History, Dashboard, Close, Person } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import Mask from "../Mask/Mask";
import { mapStateToProps } from "../../redux";
import { connect } from "react-redux";
import { signOut, changeRole } from "../../Actions/authActions/actions";
import { openMenu, closeMenu } from "../../Actions/menuActions/actions";
import { setLanguage } from "../../Actions/languageAction/actions";
import eng from "../../Icons/ENG.png";
import nl from "../../Icons/NL.png";
import logo from "../../Images/logo_hyponav_beta_white.svg";
import "./TemplateStyles.css";

class Template extends Component {
    static propTypes = {
        title: PropTypes.string,
        shouldIncludeSideBar: PropTypes.bool,
        contentFullHeight: PropTypes.bool,
        history: PropTypes.object.isRequired
    };

    static defaultProps = {
        shouldIncludeSideBar: false,
        contentFullHeight: false,
        title: null
    };

    constructor(props) {
        super(props);

        this.state = {
            appBarAnchorEl: null,
            // role: props.auth.user.role,
            currentPage: ""
        };
    }

    componentDidMount() {
        console.log(window.location.href);
    }

    matchCurrentPage = page => {
        return window.location.href.indexOf(page) > -1 ? "current-page" : null;
    };

    handleOpenUserMenu = event => {
        this.setState({ appBarAnchorEl: event.currentTarget });
    };

    handleCloseUserMenu = () => {
        this.setState({ appBarAnchorEl: null });
    };

    redirect = route => {
        if (!window.location.href.indexOf(route) > -1) {
            this.props.history.push(`/${route}`);
        }
    };

    /**
     * Mobile version function
     * * Closes the drawer on item click
     */
    mobileCloseMenu = () => {
        if (this.props.css.isMobile) {
            this.props.closeMenu();
        }
    };

    /**
     * Mobile version function
     * * Closes the drawer on mask click
     */
    maskClickHandler = () => {
        this.props.closeMenu();
    };

    // handleRoleChange = name => event => {
    //     this.setState({ [name]: event.target.value });
    //     this.props.changeRole(event.target.value);
    // };

    render() {
        const { classes, children, title, shouldIncludeSideBar, contentFullHeight, auth, menu, signOut, lang, css } = this.props;

        const { appBarAnchorEl } = this.state;

        function getFlag() {
            if (lang.dashboard.changeLanguage === "English") {
                return eng;
            } else {
                return nl;
            }
        }

        return (
            <div className={"root"}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: menu.isOpen
                    })}
                >
                    <Toolbar disableGutters={true}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginLeft: shouldIncludeSideBar ? null : 16
                                }}
                            >
                                {shouldIncludeSideBar ? (
                                    <Tooltip
                                        title={menu.isOpen ? "Close menu" : "Open menu"}
                                        aria-label={menu.isOpen ? "Close" : "Open"}
                                        disableFocusListener
                                        disableTouchListener
                                    >
                                        <IconButton
                                            color="inherit"
                                            aria-label="Open drawer"
                                            onClick={() => {
                                                menu.isOpen ? this.props.closeMenu() : this.props.openMenu();
                                            }}
                                            className={classes.menuButton}
                                        >
                                            {menu.isOpen ? <Close /> : <MenuIcon />}
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    undefined
                                )}

                                <img src={logo} alt="Logo" style={{ width: "120px", bottom: "6px", position: "relative" }} />
                                <Typography variant="h6" color="inherit" noWrap>
                                    {title === null || css.isMobile ? undefined : ` > ${title}`}
                                </Typography>
                            </div>
                            <div>
                                {auth.user !== null ? (
                                    <Fragment>
                                        {/* <Select
                                            native
                                            value={this.state.role}
                                            onChange={this.handleRoleChange("role")}
                                            inputProps={{
                                                name: "role"
                                            }}
                                        >
                                            <option value={"basic"}>basic</option>
                                            <option value={"premium"}>premium</option>
                                            <option value={"advisor"}>advisor</option>
                                            <option value={"manager"}>manager</option>
                                            <option value={"admin"}>admin</option>
                                        </Select> */}
                                        <Button
                                            style={{ marginRight: 10 }}
                                            onClick={() => {
                                                this.props.setLanguage(auth.token);
                                            }}
                                        >
                                            <Avatar alt={"Flag"} src={getFlag()} style={{ border: "1px solid rgb(212, 212, 212)" }} />
                                        </Button>
                                        <Button
                                            aria-owns={appBarAnchorEl ? "simple-menu" : undefined}
                                            aria-haspopup="true"
                                            onClick={this.handleOpenUserMenu}
                                        >
                                            {auth.user.photoURL === undefined ? (
                                                <Avatar style={{ backgroundColor: this.props.theme.palette.secondary.main }}>
                                                    {auth.user.displayName !== null ? auth.user.displayName[0] : ""}
                                                </Avatar>
                                            ) : (
                                                <Avatar alt={auth.user.displayName} src={auth.user.photoURL} />
                                            )}
                                            {!css.isMobile ? (
                                                <Typography style={{ color: "#fff", marginLeft: 10 }} variant={"body2"}>
                                                    {auth.user.displayName}
                                                </Typography>
                                            ) : null}
                                        </Button>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={appBarAnchorEl}
                                            open={Boolean(appBarAnchorEl)}
                                            onClose={this.handleCloseUserMenu}
                                        >
                                            <MenuItem onClick={this.handleCloseUserMenu}>Profile</MenuItem>
                                            <MenuItem onClick={signOut}>Logout</MenuItem>
                                        </Menu>
                                    </Fragment>
                                ) : (
                                    undefined
                                )}
                            </div>
                        </div>
                    </Toolbar>
                    {css.isMobile ? (
                        <div
                            className="secont-header"
                            style={{
                                dispaly: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "rgb(68, 138, 255)"
                            }}
                        >
                            <Typography variant="h6" color="inherit" noWrap align="center">
                                {title === null ? undefined : `${title}`}
                            </Typography>
                        </div>
                    ) : null}
                </AppBar>
                {shouldIncludeSideBar ? (
                    <Drawer
                        variant="permanent"
                        className={classNames(classes.drawer, {
                            [classes.drawerOpen]: menu.isOpen,
                            [classes.drawerClose]: !menu.isOpen
                        })}
                        classes={{
                            paper: classNames({
                                [classes.drawerOpen]: menu.isOpen,
                                [classes.drawerClose]: !menu.isOpen
                            })
                        }}
                        open={menu.isOpen}
                        onClose={this.handleDrawerClose}
                    >
                        <div className={classes.toolbar} />
                        <Divider />
                        <List>
                            <ListItem
                                button
                                className={this.matchCurrentPage("dashboard")}
                                onClick={() => {
                                    this.redirect("dashboard");
                                    this.mobileCloseMenu();
                                }}
                            >
                                <ListItemIcon>
                                    <Dashboard />
                                </ListItemIcon>
                                <ListItemText primary={lang.dashboard.nav_bar_title} />
                            </ListItem>
                            <ListItem
                                button
                                className={this.matchCurrentPage("history")}
                                onClick={() => {
                                    this.redirect("history");
                                    this.mobileCloseMenu();
                                }}
                            >
                                <ListItemIcon>
                                    <History />
                                </ListItemIcon>
                                <ListItemText primary={lang.history.title_tag} />
                            </ListItem>
                            <ListItem
                                button
                                className={this.matchCurrentPage("advisor")}
                                onClick={() => {
                                    this.redirect("advisor");
                                    this.mobileCloseMenu();
                                }}
                            >
                                <ListItemIcon>
                                    <Person />
                                </ListItemIcon>
                                <ListItemText primary={lang.advisor.nav_bar_title} />
                            </ListItem>
                        </List>
                    </Drawer>
                ) : (
                    undefined
                )}
                {menu.isOpen && css.isMobile ? <Mask click={this.maskClickHandler} /> : null}
                <div
                    className={classNames(classes.content, {
                        [classes.contentSideMenuOpen]: menu.isOpen
                    })}
                    style={{ width: shouldIncludeSideBar ? null : "100%" }}
                >
                    <div className={classes.toolbar} />
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", height: contentFullHeight ? "100%" : null }}>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

const drawerWidthOpen = 200;
const drawerWidthClose = 57;

const styles = theme => ({
    tools: {
        backgroundColor: "red"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    menuButton: {
        marginLeft: 4,
        marginRight: 4
    },
    drawer: {
        width: drawerWidthOpen,
        flexShrink: 0,
        whiteSpace: "nowrap",
        [theme.breakpoints.down(767)]: {
            position: `absolute`
        }
    },
    drawerOpen: {
        width: drawerWidthOpen,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        [theme.breakpoints.down(767)]: {
            boxShadow: "0px 0px 5px #000000",
            marginTop: "32px"
        }
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: 0,
        [theme.breakpoints.up(768)]: {
            width: drawerWidthClose
        }
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        ...theme.mixins.toolbar
    },
    content: {
        width: "100%",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up(768)]: {
            width: `calc(100% - ${drawerWidthClose}px)`
        },
        [theme.breakpoints.down(767)]: {
            marginTop: "32px"
        }
    },
    contentSideMenuOpen: {
        width: `calc(100% - ${drawerWidthOpen}px)`,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        [theme.breakpoints.down(767)]: {
            width: "100%"
        }
    }
});

export default connect(
    mapStateToProps,
    { signOut, openMenu, closeMenu, setLanguage, changeRole }
)(withStyles(styles, { withTheme: true })(Template));
